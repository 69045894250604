import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import settings from './modules/settings';



import VuexPersistence from 'vuex-persist';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v1 as uuidv4 } from 'uuid';

const cookieName = '1P_JAR';

const storageKey = 'vuex';

const encryptionToken = Cookie.get(cookieName) || uuidv4();
Vue.use(Vuex);

Cookie.set(cookieName, encryptionToken);

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: () => {
      const store = window.localStorage.getItem(storageKey);

      if (store) {
        try {
          const bytes = Crypto.AES.decrypt(store, encryptionToken);
          return JSON.parse(bytes.toString(Crypto.enc.Utf8));
        } catch (e) {
          window.localStorage.removeItem(storageKey);
        }
      }

      return null;
    },
    setItem: (key, value) => {
      const store = Crypto.AES.encrypt(value, encryptionToken).toString();
      return window.localStorage.setItem(storageKey, store);
    },
    removeItem: () => window.localStorage.removeItem(storageKey),
  },
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    user,
    settings
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
