<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="miniValueLocal"
    permanent
    fixed
    dark
    width="300"
    app
  >
    <v-app-bar
      v-if="navbarLogo"
      :dense="toolbarDense"
      dark
      class="logo-nav"
      @click.stop.prevent=""
    >
      <v-toolbar-title class="text-center">
        <v-avatar tile class="company-logo-mini" v-if="miniValueLocal">
          <v-row no-gutters>
            <v-col cols="12" class="column mt-0">
              <img
                src="../../assets/trackunit-logo-white.svg"
                alt="trackunit-logo"
                @click="navigateHome"
              />
            </v-col>
            <v-col cols="12" class="column mt-1">
              <div class="pro-serv-heading ml-1 font-12">ProServ</div>
            </v-col>
          </v-row>
        </v-avatar>
        <v-avatar tile class="company-logo" v-else>
          <v-row no-gutters>
            <v-col cols="12" class="column mt-6">
              <img
                src="../../assets/logo-trackunit.png"
                alt="trackunit-logo"
                @click="navigateHome"
              />
            </v-col>
            <v-col cols="12" class="column">
              <div class="pro-serv-heading ml-10">ProServ Portal</div>
            </v-col>
          </v-row>
        </v-avatar>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      :mini="miniValueLocal"
      :dense="navbarDense"
      :routes="routesPath"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import TheLayoutDrawerList from "./TheLayoutDrawerList.vue";
import { adminRoutes } from "../../config/admin.route.config";
//import  { constantRoutes } from '@/router';

const hasPermission = (role, route) => {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(role);
  }
  return true;
};

const filterAsyncRoutes = (roles, routes) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(roles, tmp.children);
      }
      res.push(tmp);
    }
  });
  return res;
};
// import apiService from "./../../api/api.service.js";
export default {
  name: "TheLayoutDrawer",
  props: {
    miniValue: { type: Boolean, default: true },
  },
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({
    routesPath: [],
    color: "dark",
    drawer: true,
    mini: true,
    miniValueLocal: false,
    finalRoutesList: [],
    supportToolRoutes: [
      {
        name: "Group Assignment Tool",
        path: "/group-assignment-tool",
        alias: "/",
        toolId: "775062DD-EF70-4EE8-B079-5B0D95EB9B13",
        meta: {
          title: "Group Assignment Tool",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-select-group",
        },
      },
      {
        name: "Hours Update Tool",
        path: "/hours-update-tool",
        alias: "/",
        toolId: "52C01730-7DF1-40BF-86CC-14345AE66513",
        meta: {
          title: "Hours Update Tool",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-wrench-clock",
        },
      },
      {
        name: "Metadata Update Tool",
        path: "/metadata-update-tool",
        alias: "/",
        toolId: "3C134EE5-BA6B-45C7-92F0-A74AB48875AF",
        meta: {
          title: "Metadata Update Tool",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-text-box-check-outline",
        },
      },
    ],
    deploymentToolRoutes: [
      {
        name: "Unit 360",
        path: "/audit-tool",
        alias: "/",
        toolId: "B4ADFA53-83C3-46FF-A158-F1CBDBB0E97A",
        meta: {
          title: "Unit 360",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-checkbox-marked-outline",
        },
      },
      {
        name: "Certify Install",
        path: "/certify-install",
        alias: "/",
        toolId: "7bc6dba0-540c-4723-8dd3-8b91d34a098a",
        meta: {
          title: "Certify Install",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-certificate",
        },
      },
      {
        name: "Field Install Tool",
        path: "/field_tool_management",
        alias: "/",
        toolId: "c6d3ac4c-dcab-4324-a35b-6b8fc096e1ff",
        meta: {
          title: "Field Install Tool",
          roles: ["Admin", "Manager", "User"],
          icon: "mdi-map-marker-path",
        },
      },
    ]
  }),
  watch: {
    miniValue() {
      this.miniValueLocal = this.miniValue;
      this.$emit("mini", this.miniValue);
    },
  },
  computed: {
    ...mapGetters([
      "navbarDense",
      "navbarShow",
      "navbarLogo",
      "toolbarDense",
      "roles",
      "userId",
      "toolIds",
    ]),
  },
  created() {
    this.routesPath = filterAsyncRoutes(this.roles, adminRoutes);
  },
  mounted() {
    this.miniValueLocal = this.miniValue;
    this.getToolsList();
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch("NavbarState", { state });
    },
    navigateHome() {
      this.$router.push("/");
    },
    async getToolsList() {
      if (this.roles == "Manager" || this.roles == "User") {
        let toolIds = this.toolIds;
         // Customer Reports Tool 
         let tempCustomerReportsTools = [];
         let finalCustomerReportsTools = [];
         let customerReports = [];
        // customerReports.push(this.customerReports);
         for(var x = 0; x < customerReports.length; x++){
            for (var y = 0; y < toolIds.length; y++) {
              if(customerReports[x].toolId == toolIds[y]){
                tempCustomerReportsTools.push(customerReports[x]);
              }
            }
         }
         finalCustomerReportsTools = tempCustomerReportsTools;
          if(finalCustomerReportsTools.length>0 && this.roles == 'Manager'){
           this.routesPath.splice(2, 0, finalCustomerReportsTools[0])
          }
           if(finalCustomerReportsTools.length>0 && this.roles == 'User'){
           this.routesPath.splice(1, 0, finalCustomerReportsTools[0])
          }
            // Deployment Tools
        let tempDeploymentTools = [];
        let finalDeploymentList = [];
        for (var i = 0; i < this.deploymentToolRoutes.length; i++) {
          for (var j = 0; j < toolIds.length; j++) {
            if (this.deploymentToolRoutes[i].toolId == toolIds[j]) {
              tempDeploymentTools.push(this.deploymentToolRoutes[i]);
            }
          }
        }
        finalDeploymentList = tempDeploymentTools;
        // let deploymentToolChildren = finalDeploymentList;

        // Support Tools
        let tempSupportList = [];
        let finalSupportTools = [];
        for (var s = 0; s < this.supportToolRoutes.length; s++) {
          for (var t = 0; t < toolIds.length; t++) {
            if (this.supportToolRoutes[s].toolId == toolIds[t]) {
              tempSupportList.push(this.supportToolRoutes[s]);
            }
          }
        }
        finalSupportTools = tempSupportList;
        // let supportToolChildren = finalSupportTools;

        //Pushing all dynamic tools into main routes array
        for (var m = 0; m < this.routesPath.length; m++) {
          if (this.routesPath[m].name == "Deployment Tools") {
            this.routesPath[m].children.push(...finalDeploymentList);
          } else if (this.routesPath[m].name == "Support Tools") {
            this.routesPath[m].children.push(...finalSupportTools);
          } 
        }
      } else {
        
        //Pushing all dynamic tools into main routes array
        for (var r = 0; r < this.routesPath.length; r++) {
          if (this.routesPath[r].name == "Deployment Tools") {
            this.routesPath[r].children.push(...this.deploymentToolRoutes);
          } else if (this.routesPath[r].name == "Support Tools") {
            this.routesPath[r].children.push(...this.supportToolRoutes);
          } 
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/styles/variables.scss";
.v-list-item__title {
  font-family: "Segoe UI", "Roboto", sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  margin-left: 4px;
  height: 52px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
}
.mdi {
  font-size: 35px !important;
}
a.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  height: 52px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin: 0;
  margin-right: 16px;
}
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0;
  padding-top: 5px;
}
.navigation-icon {
  color: #fff !important;
}
.company-logo-mini {
  width: 48px !important;
  height: 48px !important;
}
.company-logo-mini img {
  width: 29.19px;
  height: 32px;
  cursor: pointer;
}
.company-logo img {
  height: 32px;
  width: auto;
  cursor: pointer;
}

.v-toolbar__content {
  text-align: center;
}
.v-toolbar__title {
  width: 100%;
}

.company-logo .column {
  width: 100% !important;
}
.pro-serv-heading {
  font-size: 14px;
  color: #f7402f;
  font-weight: 500;
  margin-top: -10px;
}
.font-12 {
  font-size: 11px !important;
}
</style>
