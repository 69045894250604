<template>
  <v-footer
    v-if="footerShow"
    app
    padless
    inset
  >
    <v-card
      flat
      tile
      width="100%"
      class="primary text-center"
    >
     

      <v-divider />

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Unqork Admin</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheLayoutFooter',
  components: {},
  data: () => ({
  }),
  computed: {
    ...mapGetters([
      'footerShow',
    ]),
  },
  methods: {},
};
</script>
