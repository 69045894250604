import pageheader from "@/components/pageheader";
import { mapGetters } from "vuex";
import utils from "../../../utils/util";
import app from "../../../utils/app.json";
import rules from "../../../utils/rules";
import { VueEditor } from "vue2-editor";
import Dropzone from "../../../views/DropZone.vue";
// import apiService from "../../../api/api.service";
import config from "../../../../secrets/config";
import moment from "moment";
// import ticketAPI from "../../../api/releases.service";
import ticketAPI from "../../../api/supporttickets.service";
import InfoDialog from "@/components/InfoDialog";
import constants from "@/config/constants";
export default {
  name: "support-tickets",
  components: { pageheader, VueEditor, Dropzone, InfoDialog },
  props: [],
  data() {
    return {
      input: {},
      // Get Tickets List
      mergeWithNewData: ["All Tickets", "Active", "Closed"],
      sortBy: "Active",
      ticketStatus: "active",
      options: {},
      loading: false,
      ticketsList: [],
      headers: [
        {
          text: "SNO",
          align: "center",
          sortable: false,
          value: "sno",
        },
        { text: "Ticket #", value: "jirakey" },
        { text: "Summary", value: "summary" },
        { text: "Priority", value: "priority" },
        { text: "Tags", value: "bugTags" },
        { text: "Status", value: "status", width: "180px" },
        { text: "Assignee", value: "assignee", width: "140px" },
        { text: "Reported By", value: "createdBy" },
        { text: "Reported On", value: "createdDate" },
        { text: "Actions", value: "action" },
      ],
      items: [],
      model: [],
      tagId: "",
      searchtag: null,
      //create New Ticket
      createTicket: [],
      ticketDialog: false,
      priority: app.priority,
      rules: rules,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      valid: true,
      Summary: "",
      description: "",
      createModel: [],
      search: "",
      createdTagItems: [],
      btnloading: false,
      files: [],
      supportUserId: "",
    };
  },
  computed: {
    ...mapGetters(["roles", "supportTicketsReferenceId"]),
  },
  watch: {
    createModel(val, prev) {
      if (val.length === prev.length) return;

      this.createModel = val.map((v) => {
        if (typeof v === "string") {
          v = {
            name: v,
          };
        }
        return v;
      });
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const format = "YYYY-MM-DD";
        return moment(value).format(format);
      }
    },
    lowercase: function (value) {
      if (value) {
        return value.toLowerCase();
      }
    },
  },
  mounted() {
    this.input = utils.getInputContent("support", this.roles);
    if (
      this.supportTicketsReferenceId !== null &&
      this.supportTicketsReferenceId !== "" &&
      this.supportTicketsReferenceId !== undefined
    ) {
      //this.getTicketsList();
      //this.gettagdata();
    }
  },
  methods: {
    openModal() {
      this.$refs.modal.showModal();
    },
    //Tickets List

    async getTicketsList() {
      this.loading = true;
      if (this.sortBy == "Active") {
        this.ticketStatus = "Active";
      } else if (this.sortBy == "Closed") {
        this.ticketStatus = "Closed";
      } else {
        this.ticketStatus = "All";
      }
      // let headerpayload = {
      //   clientId: config.CLIENT_ID
      // }
      try {
        let response = await ticketAPI.getTickets(
          this.supportTicketsReferenceId,
          this.ticketStatus,
          this.tagId
        );
        if (response) {
          this.loading = false;
          this.ticketsList = [];
          this.ticketsList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.ticketsList.filter((item) => {
            if (
              (item.status.toString().toLowerCase() == "in progress") |
              (item.status.toString().toLowerCase() == "inprogress")
            ) {
              item.color = "#f7cb41";
              item.textcolor = "black";
            } else if (
              (item.status.toString().toLowerCase() == "customer feedback") |
              (item.status.toString().toLowerCase() == "customerfeedback")
            ) {
              item.color = "#f85359";
              item.textcolor = "white";
            } else if (
              (item.status.toString().toLowerCase() == "to do") |
              (item.status.toString().toLowerCase() == "todo")
            ) {
              item.color = "#008FFB";
              item.textcolor = "white";
            } else if (
              (item.status.toString().toLowerCase() == "on hold") |
              (item.status.toString().toLowerCase() == "onhold") |
              (item.status.toString().toLowerCase() == "hold")
            ) {
              item.color = "#932AB6";
              item.textcolor = "white";
            } else if (
              item.status.toString().toLowerCase() == "approve to close"
            ) {
              item.color = "#00c48c";
              item.textcolor = "white";
            } else if (item.status.toString().toLowerCase() == "done") {
              item.color = "#4D5656";
              item.textcolor = "white";
            }
            if (item.priority == "Critical") {
              item.criticalPriority = true;
            } else if (item.priority == "Severe") {
              item.severePriority = true;
            } else if (item.priority == "High") {
              item.highPriority = true;
            }
          });
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error("Unable to load the report requests" + err);
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    //Serach with Tag
    async gettagdata() {
      let tagslist = await ticketAPI.getTagData();
      if (tagslist) {
        this.items = tagslist.map((e) => {
          return {
            name: e.name,
            id: e.id,
          };
        });
        this.createdTagItems = tagslist.map((e) => {
          return {
            name: e.name,
            id: e.id,
          };
        });
      }
    },
    readTagIdsFromTag() {
      this.tagId = "";
      if (this.model.length != 0) {
        this.tagId = this.model
          .map((x) => x["id"])
          .join(",")
          .toString();
      }
      this.getTicketsList();
    },

    // Open dialog for add new ticket
    openNewTicket() {
      this.ticketDialog = true;
      this.clientId = config.CLIENT_ID;
    },
    // File upload methods
    dropzoneS(file) {
      utils.fileToBase64(file).then((base64String) => {
        let fileData = {
          fileName: file.name,
          fileContent: base64String.split(",")[1],
        };
        this.files.push(fileData);
      });
    },
    dropzoneR(file) {
      utils.fileToBase64(file).then((base64String) => {
        let filterFiles = this.files.filter(function (a) {
          return a.fileName != file.name && a.fileContent != base64String;
        });
        this.files = filterFiles;
      });
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.dropzone.removeAllFiles();
      this.ticketDialog = false;
      this.createTicket.description =""

    },
    createFilter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    // remove(item) {
    //   const index = this.friends.indexOf(item.name);
    //   if (index >= 0) this.friends.splice(index, 1);
    // },
    // Create a new ticket
    async addTicket() {
      if (this.$refs.form.validate()) 
      {
        if (this.createTicket.description) {
          this.btnloading = true;
          let filesLength = this.files.length;
          let tagsLength = this.createModel.length;
          let payload = {
            userId: this.$store.getters.supportTicketsReferenceId,
            description: this.createTicket.description
              ? this.createTicket.description
              : "",
            summary: this.createTicket.summary ? this.createTicket.summary : "",
            priority: this.createTicket.priority
              ? this.createTicket.priority
              : "",
            files: filesLength > 0 ? this.files : [],
            tags: tagsLength > 0 ? this.createModel : [],
          };
          if (this.createTicket.description.length <= constants.DESCRIPTION_LENGTH)
          {
            try {
              let apiResponse = await ticketAPI.reportATicket(payload);
              this.ticketDialog = false;
              this.$refs.form.reset();
              if (apiResponse) {
                this.$toast.success("Ticket has been created successfully");
                //this.getTicketsList();
              }
            } catch (err) {
              this.$refs.form.reset();
              this.ticketDialog = false;
              this.$toast.error("Unable to report a ticket" + err);
            }
          } else {
            this.$toast.error("Exceeded the Description Limit");
          }
        } else {
          this.$toast.error("Please enter description");
        }
        this.btnloading = false;
      }
    },
  },
};
