import moment from "moment";
import InfoDialog from "@/components/InfoDialog";
export default {
  name: "pageheader",
  components: { InfoDialog },
  props: [
    "input",
    "viewTicket",
    "jiraKey",
    "priority",
    "assignee",
    "reportedBy",
    "reportedOn",
    "dataIntegrity",
  ],
  data() {
    return {
      openedPanel: [],
      isExpanded: false,
      showbanner:true
    };
  },
  computed: {},
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "YYYY-MM-DD";
        return moment(value).format(format);
      }
    },
    lowercase: function(value) {
      if (value) {
        return value.toLowerCase();
      }
    },
  },
  mounted() {},
  methods: {
    handleToggle() {
      if (this.isExpanded) {
        this.openedPanel = [0];
        this.showbanner = false;
      } else {
        this.openedPanel = [1];
        this.showbanner = true;
      }
    },
    toggleClick() {
      this.isExpanded = !this.isExpanded;
      this.handleToggle();
    },
    openModal() {
      this.$refs.modal.showModal();
    },
  },
};
