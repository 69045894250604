//roles:["Admin","Developers","Support Admin","Support Manager","Sales"],

export const adminRoutes = [
  {
    path: "/",
    children: [
      {
        path: "/",
        alias: "/",
        name: "Home",
        meta: {
          title: "Dashboard",
          icon: "mdi-home-outline"
        }
      }
    ]
  },
  {
    path: "/customers",
    alias: "/",
    name: "Customer Projects",
    meta: {
      title: "Customer Projects",
      roles: [
        "Admin",
        "Manager",
      ],
      icon: "mdi-account-star-outline"
    }
  },
  {
    path: "/customer-deatils/",
    alias: "/",
    name: "Details",
    meta: {
      title: "Customer Details",
      roles: ["Customer"],
      icon: "mdi-account-arrow-right"
    }
  },
  
  {
    path: "/",
    alias: "/deployment",
    name: "Deployment Tools",
    meta: {
      title: "Deployment Tools",
      roles: [
        "Admin",
        "Manager",
        "User"
      ],
      icon: "mdi-cloud-upload-outline"
    },
    children: [
      {
        path: "/data-integrity-report-dashboard",
        alias: "/",
        name: "Data Integrity Dashboard",
        meta: {
          title: "Data Integrity Dashboard",
          roles: [
            "Admin",
            "Manager",
            "User"
          ],
          icon: "mdi-file-document"
        }
      }
    ]
  },
  {
    path: "/",
    alias: "/supporttools",
    name: "Support Tools",
    meta: {
      title: "Support Tools",
      roles: [
        "Admin",
        "Manager",
        "User"
      ],
      icon: "mdi-lumx"
    },
    children: [
    ]
  },
  {
    path: "/",
    alias: "/Administration",
    name: "Administration",
    meta: {
      title: "Administration",
      roles: ["Admin"],
      icon: "mdi-shield-crown-outline"
    },
    // component: Layout,
    children: [
      {
        path: "/user-management/list",
        alias: "/",
        name: "User Management",
        meta: {
          title: "UserManagement",
          roles: ["Admin"],
          icon: "mdi-account-multiple-check-outline"
        }
      },
      {
        path: "/clients-list",
        alias: "/",
        name: "Customer Management",
        meta: {
          title: "Customer Management",
          roles: ["Admin"],
          icon: "mdi-card-account-details-outline"
        }
      }
    ]
  }
];
export const hasAccess = {
  dashboard: {
    Sales: {
      environment: false,
      customerReports: true,
      internalReports: true,
      reportStats: true,
      reportAbug: true,
      reportRequest: true
    },
    "Support Manager": {
      customerReports: false,
      internalReports: true,
      ticketStats: true,
      reportStats: false,
      reportAbug: true,
      reportRequest: false
    }
  },
  viewReport: {
    "Support Admin": {
      updateStatus: false,
      editReport: true
    },
    Sales: {
      updateStatus: false,
      editReport: true
    }
  },
  accessNotes: ["Admin"]
};
