import releasesService from "../../../api/releases.service";
import moment from "moment";
import env from "../../../../secrets/config";
import pageheader from "../../pageheader";
import utils from "../../../utils/util";
import { mapGetters } from "vuex";
export default {
  name: "releases-list",
  components: { pageheader },
  props: [],
  data() {
    return {
      input: {},
      options: {},
      loading: false,
      search: "",
      ReleasesList: [],
      mergeWithNewData: ["All Tickets", "Active", "Closed"],
      sortBy: "All Tickets",
      releaseStatus: "active",
      headers: [
        {
          text: "SNO",
          align: "center",
          sortable: false,
          value: "sno",
          class: `releaseslist`
        },
        { text: "Date", value: "date", class: `releaseslist` },
        { text: "Environment", value: "environment", class: `releaseslist` },
        {
          text: "Title",
          align: "left",
          value: "title",
          class: `releaseslist`,
          width: "900px"
        },

        // { text: 'Status', value: 'status' },

        {
          text: "Actions",
          value: "action",
          sortable: false,
          class: `releaseslist`
        }
      ],
      role: "",
      clientId: ""
    };
  },
  computed: {
    ...mapGetters(["roles"])
  },
  mounted() {
    this.role = this.$store.getters.roles;
    this.readDataFromAPI();
    // getting input content
    this.input = utils.getInputContent("releaseNotes", this.roles);
  },
  filters: {
    format(value) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  methods: {
    // Get All Releases List
    async readDataFromAPI() {
      this.loading = true;
      try {
        // if (this.sortBy == "Active") {
        //   this.releaseStatus = "Active";
        // } else if (this.sortBy == "Closed") {
        //   this.releaseStatus = "Closed";
        // } else {
        //   this.releaseStatus = "All";
        // }

        //Set default Release status to ALL
        this.releaseStatus = "All";

        let headerpayload = {
          clientId: env.CLIENT_ID
        };

        let result = await releasesService.getReleases(
          headerpayload,
          this.releaseStatus
        );
        if (result) {
          this.loading = false;
          this.ReleasesList = result.map((d, index) => ({
            ...d,
            sno: index + 1
          }));
        }
      } catch (err) {
        this.$toast.error("Unable to load the releases" + err);
        this.loading = false;
      }
    },
    newRelease() {
      this.$router
        .push({
          path: "/releases/add"
        })
        .catch(() => {});
    }
  }
};
