
export default {
  name: 'info-dialog',
  components: {},
  props: ["priority","role","status","applicationDescription"],
  data () {
    return {
  infoDialog : false,
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    showModal() {
      this.infoDialog = true;
      },
  }
}


