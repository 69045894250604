import rules from "../../../utils/rules";
import moment from "moment";
import VueDateTime from "../../../views/vue-date-time";
import releasesService from "../../../api/releases.service";
import app from "../../../utils/app.json";
import { VueEditor } from "vue2-editor";
import env from "../../../../secrets/config";
import pageheader from "../../pageheader";
import utils from "../../../utils/util";
import { mapGetters } from "vuex";
export default {
  name: "view-releases",
  components: { VueDateTime, VueEditor, pageheader },
  props: [],
  data() {
    return {
      input: {},
      viewReleasesList: [],
      environment: app.releasesEnvironment,
      dialog: false,
      deleteDialog: false,
      deleteNotesDialog: false,
      headers: [
        {
          text: "S.No",
          value: "sno",
          class: `releases-notes`,
          align: "left",
          width: "5px"
        },
        {
          text: "Details",
          value: "details",
          class: `releases-notes`,
          align: "left",
          width: "auto"
        },
        {
          text: "Status",
          value: "status",
          class: `releases-notes`,
          align: "left",
          width: "auto"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          class: `releases-notes`,
          align: "left",
          width: "auto"
        }
      ],
      valid: true,
      updateRelease: {},
      releaseDateFormatted: "",
      isReleaseDateShowMask: true,
      isEditing: false,
      isDetailsEditing: false,
      rules: rules,
      loading: false,
      createdUser: "",
      isView: false,
      isDetailsView: false,
      envValue: "",
      statusList: ["Open", "Approved", "Rejected"],
      options: {},
      clientId: "",
      comments: "",
      newDialog: false,
      UserName: "",
      getReleaseNotes: [],
      details: [],
      newdetails: "",
      NotesId: "",
      CommentsList: [],
      comment: "",
      role: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"]
      ],
      editor: false,
      releaseDate: false
    };
  },
  watch: {
    isEditing(value) {
      this.isView = value;
    },
    isDetailsEditing(value) {
      this.isDetailsView = value;
    },
    'updateRelease.releaseDate' (val) {
      this.releaseDateFormatted = this.formatDateNew(val)
      this.isReleaseDateShowMask = false;
      setTimeout(() => {
        this.isReleaseDateShowMask = true;
      }, 1);
    },
  },
  computed: {
    ...mapGetters(["roles"])
  },
  mounted() {
    this.role = this.$store.getters.roles;
    this.UserName = this.$store.getters.name;
    this.readDataById();
    // getting input content
    this.input = utils.getInputContent("viewRelease", this.roles);
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "YYYY-MM-DD   hh:mm A";
        return moment(value).format(format);
      }
    },
    format(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DD");
      }
    },
    formatHtmlContent(val) {
      if (val) {
        return val.replace(/(<([^>]+)>)/gi, "");
      }
      return "";
    }
  },
  methods: {
    formatDateNew (date) {
      if (!date) return ""

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date, isReleaseDate) {
      if (!date) return ""

      const [month, day, year] = date.split('/')
      if (!month || month<1 || month>12 || !day ||day<1 || day>31 || !year || year<2000 || year>2100) {
        if (isReleaseDate) {
          this.updateRelease.releaseDate = "";
          this.releaseDateFormatted = "";
          return ""
        }
      } else {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }

    },
    DeleteConformation() {
      this.deleteDialog = true;
    },
    cancelUpdate(isedit) {
      this.isEditing = !isedit;
    },
    cancelDetailsUpdate(isedit) {
      this.isDetailsEditing = !isedit;
    },
    // Get Release By Id
    async readDataById() {
      this.loading = true;
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      try {
        let result = await releasesService.getReleasesById(
          this.$route.params.id,
          headerpayload
        );
        if (result) {
          this.loading = false;
          this.updateRelease = result;
          this.viewReleasesList = result.releaseNotes;
          this.viewReleasesList = this.viewReleasesList.map((d, index) => ({
            ...d,
            sno: index + 1
          }));
          this.updateRelease.releaseDate = moment(
            this.updateRelease.date
          ).format("YYYY-MM-DD");
        }
      } catch (err) {
        this.$toast.error("Unable to load the data" + err);
        this.loading = false;
      }
    },
    // Edit & Update Release By Id
    async editReleases() {
      if (
        this.updateRelease.releaseDate == "" ||
        this.updateRelease.releaseDate == undefined ||
        this.updateRelease.releaseDate == null
      ) {
        this.$toast.error("Please enter date. ");
        this.loading = false;
        return;
      }
      this.loading = true;
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      let payload = {
        userId: this.UserName,
        title: this.updateRelease.title,
        environment: this.updateRelease.environment,
        releaseDate: this.updateRelease.releaseDate
      };
      try {
        let result = await releasesService.UpdateRelease(
          this.$route.params.id,
          payload,
          headerpayload
        );
        if (result.isSuccess == true) {
          this.loading = false;
          this.$toast.success(result.systemMessage);
          this.cancelUpdate(true);
        }
      } catch (err) {
        this.$toast.error("Unable to update the Release" + err);
        this.loading = false;
      }
    },
    // Delete Release By Id
    async deleteRelease() {
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      try {
        let result = await releasesService.DeleteReleaseById(
          this.$route.params.id,
          headerpayload
        );
        if (result) {
          this.$toast.success(result.systemMessage);
          this.$router.push({
            path: "/releases/list"
          });
        }
      } catch (err) {
        this.$toast.error("Unable to delete the Release" + err);
      }
    },
    // Get Release Notes By Id
    async OpenDialog(releaseNotesId) {
      this.dialog = true;
      this.NotesId = releaseNotesId;
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      try {
        let result = await releasesService.GetReleasesNotes(
          releaseNotesId,
          headerpayload
        );
        if (result) {
          this.loading = false;
          this.getReleaseNotes = result;
          this.getReleaseNotes.status = result.status;
        }
      } catch (err) {
        this.$toast.error("Unable to load the data" + err);
        this.loading = false;
      }
    },
    // Edit & Update Release Notes By Id
    async UpdateReleasesNotesById(NotesId) {
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      this.CommentsList = [];
      this.CommentsList.push({
        comment: this.comment,
        createdBy: this.getReleaseNotes.createdBy,
        createdDate: this.getReleaseNotes.createdDate
      });
      let payload = {
        status: this.getReleaseNotes.status,
        details: this.getReleaseNotes.details,
        comments: this.CommentsList,
        userId: this.UserName
      };
      if (this.comment == "" && this.getReleaseNotes.status == "Rejected") {
        this.$toast.warning("Please add some commnets");
        return;
      }
      try {
        let result = await releasesService.UpdateReleaseNotes(
          NotesId,
          payload,
          headerpayload
        );
        this.dialog = false;
        if (result.isSuccess == true) {
          this.loading = false;
          this.$toast.success(result.systemMessage);
          this.comment = "";
          this.readDataById();
          this.cancelDetailsUpdate(true);
        }
      } catch (err) {
        this.$toast.error("Unable to update the Release Notes" + err);
        this.loading = false;
        this.comment = "";
        this.readDataById();
      }
    },
    async DeleteReleaseNotesById() {
      let headerpayload = {
        clientId: env.CLIENT_ID
      };
      try {
        let result = await releasesService.DeleteReleaseNotes(
          this.NotesId,
          headerpayload
        );
        if (result.isSuccess == true) {
          this.deleteNotesDialog = false;
          this.dialog = false;
          this.$toast.success(result.systemMessage);
          this.readDataById();
        }
      } catch (err) {
        this.$toast.error("Unable to delete Release Notes" + err);
        this.readDataById();
        this.deleteNotesDialog = false;
        this.dialog = false;
      }
    },
    // Close Release Notes Dialog
    closeDialog() {
      this.dialog = false;
      this.comment = "";
    },
    // Open Dialog for Add New
    openNewDialog() {
      this.newDialog = true;
      this.newdetails = "";
    },
    // Close Dialog for Add New
    closenewDialog() {
      this.newDialog = false;
    },
    // Post Add New Data
    async AddNewReleaseDetails() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let payload = {
          userId: this.UserName,
          releaseId: this.$route.params.id,
          details: this.newdetails
        };
        let headerpayload = {
          clientId: env.CLIENT_ID
        };
        this.newDialog = false;
        try {
          let apiResponse = await releasesService.AddNewDetails(
            payload,
            headerpayload
          );
          if (apiResponse.isSuccess == true) {
            this.loading = false;
            this.$toast.success(apiResponse.systemMessage);
            this.readDataById();
            (this.newdetails = ""), (this.newDialog = false);
          }
        } catch (err) {
          this.loading = false;
          this.readDataById();
          (this.newdetails = ""), (this.newDialog = false);
          this.$toast.error("Unable to create a new release" + err);
        }
      }
    }
  }
};
