<template>
  <v-list :dense="dense" style="margin-top: 40px" class="navigation-list">
    <!-- @click.stop.prevent="toggleNavbar(item)" -->
    <div
      v-for="item in navigation.filter(item => !item.hidden)"
      :key="item.title"
    >
      <!-- @click.stop.prevent="" -->
      <v-list-item
        v-if="isVisibleItem(item) && onlyOneChild.path != '/data-integrity-report-dashboard'"
        :to="resolvePath(onlyOneChild.path)"
        ripple="ripple"
      @click.stop.prevent=""
        :class="item.name === parentPath ? 'v-list-item--active' : ''"
      >
        <v-list-item-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="navigation-icon"
                v-if="item.path == '/'"
              >
                mdi-home-outline
              </v-icon>
              <v-icon v-bind="attrs" v-on="on" class="navigation-icon" v-else>
                {{ item.meta.icon }}
              </v-icon>
            </template>
            <span>{{ getListTitle(onlyOneChild) }}</span>
          </v-tooltip>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="menu-title">
            {{ getListTitle(onlyOneChild) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
         <!-- @click.stop.prevent="" -->
      <v-list-group
        v-else-if="item.name == 'Support Tools' && item.name == 'Deplyment Tools'"
        :value="isActiveInvoicing"
        class="group-name"
     @click.stop.prevent=""
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="menu-title group-name">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="group-name-icon navigation-icon"
                    :class="mini ? '' : 'mr-3 mb-2'"
                    >mdi-finance</v-icon
                  >
                </template>
                <span>{{ getListTitle(item) }}</span>
              </v-tooltip>
              <v-icon v-if="mini" class="navigation-icon"
                    >mdi-chevron-down</v-icon
                  >
              <span v-if="!mini">{{ getListTitle(item) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
          <!-- @click.stop.prevent="" -->
        <template v-for="(subItem, index) in item.children">
          <v-list-item
            :key="index"
            v-if="isVisibleItem(subItem)"
            :to="resolvePath(subItem.path)"
            ripple="ripple"
          @click.stop.prevent=""
            :class="subItem.name === parentPath ? 'v-list-item--active' : ''"
          >
            <v-list-item-icon class="layout-drawer__icon">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="navigation-icon">{{
                    getListIcon(subItem)
                  }}</v-icon>
                </template>
                <span>{{ getListTitle(subItem) }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="menu-title">
                {{ getListTitle(subItem) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
   <!-- @click.stop.prevent="" -->
      <v-list-group
        v-else
        :value="isActive"
        class="group-name"
     @click.stop.prevent=""
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="menu-title group-name">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="group-name-icon navigation-icon"
                    :class="mini ? '' : 'mr-3 mb-2'"
                    >{{ getListIcon(item) }}</v-icon
                  >
                </template>
                <span>{{ getListTitle(item) }}</span>
              </v-tooltip>
              <v-icon v-if="mini" class="navigation-icon"
                    >mdi-chevron-down</v-icon
                  >
              <span v-if="!mini">{{ getListTitle(item) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
          <!-- @click.stop.prevent="" -->
        <template v-for="(subItem, index) in item.children">
          <v-list-item
            :key="index"
            v-if="isVisibleItem(subItem)"
            :to="resolvePath(subItem.path)"
            ripple="ripple"
          @click.stop.prevent=""
            :class="subItem.name === parentPath ? 'v-list-item--active' : ''"
          >
            <v-list-item-icon class="layout-drawer__icon">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="navigation-icon">{{
                    getListIcon(subItem)
                  }}</v-icon>
                </template>
                <span>{{ getListTitle(subItem) }}</span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="menu-title">
                {{ getListTitle(subItem) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
import { resolve } from "path";

export default {
  name: "TheLayoutDrawerList",
  props: {
    dense: Boolean,
    iconShow: Boolean,
    isNest: Boolean,
    mini: Boolean,
    routes: {
      type: Array,
      default: () => {}
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    this.onlyOneChild = null;

    return {
      navigation: this.routes,
      parentPath: null
    };
  },
  watch: {
    $route() {
      //scroll to top of the page
      window.scrollTo(0, 0);
      this.parentPath = this.$route.matched[0].name;
    }
  },
  computed: {
    isActive() {
      if (
        this.parentPath == "All Reports" ||
        this.parentPath == "Report Mappings" ||
        this.parentPath == "Updates" ||
        this.parentPath == "BigQuery Dashboard" ||
        this.parentPath == "User Management" ||
        this.parentPath == "Releases" ||
        this.parentPath == "Report Tracking"
      ) {
        return true;
      }
      return false;
    },
    isActiveInvoicing() {
      if (
        this.parentPath == "Audit Tool" ||
         this.parentPath == "Deployment Tools" ||
        this.parentPath == "Support Tools"
      ) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.parentPath = this.$route.matched[0] ? this.$route.matched[0].name : "";
  },
  methods: {
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    },
    isVisibleItem(item) {
      let test =
        this.hasOneVisibleChild(item.children, item) &&
        (!this.onlyOneChild.children || this.onlyOneChild.noVisibleChildren) &&
        !item.alwaysShow;
      return test;
    },
    hasOneVisibleChild(children = [], parent) {
      const visibleChildren = children.filter(item => {
        if (item.hidden) return false;
        // Temp set(will be used if only has one visible child)
        this.onlyOneChild = item;
        return true;
      });

      // When there is only one child router, the child router is displayed by default
      if (visibleChildren.length === 1) {
        this.onlyOneChild.path = resolve(parent.path, this.onlyOneChild.path);
        this.onlyOneChild.meta.icon =
          this.onlyOneChild.meta.icon || parent.meta.icon || "";

        return true;
      }

      // Show parent if there are no child router to display
      if (visibleChildren.length === 0) {
        this.onlyOneChild = { ...parent, noVisibleChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(path) {
      if (this.isExternal(path)) {
        return path;
      }
      return resolve(this.basePath, path);
    },
    getListIcon(item) {
      return this.iconShow && item.meta ? item.meta.icon : " ";
    },
    getListTitle(item) {
      return item.name ? item.name : "";
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables.scss";
.layout-drawer {
  padding-bottom: 0px;
  padding-top: 0px;
}
.v-list-item__title {
  font-family: "Segoe UI", "Roboto", sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 16px;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  margin-left: 4px;
  height: 52px;
  padding: 10px 18px;
  display: flex;
  align-items: center;
}

a.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  height: 52px;
  padding: 10px 18px;
  display: flex;
  align-items: center;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin: 0;
  margin-right: 16px;
}
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0;
  padding-top: 5px;
}
.arrow-button {
  top: 55px;
  width: 30px;
  background: $black-shade-3;
  transition: left 0.1s ease-in-out 0s, visibility 0.1s ease-in-out 0s;
  position: absolute;
  box-shadow: rgb(56 58 60) 0px 0px 0px 1px !important;
  z-index: 1;
}
.navigation-icon {
  color: #fff !important;
}
.arrow-button-right {
  left: 73px;
}

.arrow-button-left {
  left: 280px;
}
.company-logo-mini {
  width: 48px !important;
  height: 48px !important;
}
.company-logo-mini img {
  width: 29.19px;
  height: 32px;
  cursor: pointer;
}
.company-logo img {
  height: 32px;
  width: auto;
  cursor: pointer;
}

.v-toolbar__content {
  text-align: center;
}
.v-toolbar__title {
  width: 100%;
}
.menu-title {
  color: $neutral-white;
}
.v-list-group--active {
  //white--textborder-bottom: 1px solid $neutral-gray-8;
  padding-bottom: 10px;
}
</style>
