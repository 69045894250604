const rules = {
  required: [(v) => !!v || "Required"],
  selectRequired: [(v) => !! v.length>0 || "required"],
  nameRules: [
    (v) => !!v || "Name is required",
    (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
  ],
  emailRules: [
    (v) => !!v || "Email is required",
    (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Email must be valid",
  ],
  email: v => {
    
    if(!v){
      return true
    }
    else if(v.length > 0 && v != null) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(v) || 'Invalid Email.';
      }
  },
  phone: v => {
    if(!v){
      return true
    }
      else if(v.length > 0 && v != null) {
        const pattern = /^(?:[0-9]{9,12}|)$/;
        return pattern.test(v) || "Please enter valid mobile number. Just the 9-12 numbers!"
      }
  },
  passwordRules: [
    (v) => !!v || "Password is required",
    (v) => (v && v.length >= 5) || "Password must have 5+ characters",
    (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
    (v) => /(?=.*\d)/.test(v) || "Must have one number",
    (v) => /([!@$%])/.test(v) || "Must have one special character [!@#$%]",
  ],
  historyNumber: [ (v) => (v > -1 && v <= 180) || "Must Be between 0-180"
],
  phoneNumber: v =>
  /^(?:[0-9]{9,12}|)$/.test(v) ||
  "Please enter valid mobile number. Just the 9-12 numbers!",
  // counter: [
  //   v => v.length <= 4 || 'Min 4 character',
  //   (v) => v >= 0 || 'Please enter positive number'],

  counter: value => (value && value.length <= 1) || 'Max 1 character',
  
    acceptPositive: [
      (v) => !!v || "Required",
      (v) => v >= 0 || 'Please enter positive number'],
      acceptOnlyPositive: [
        (v) => v >= 0 || 'Please enter positive number'],
};
export default rules;
