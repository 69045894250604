var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isUserLoggedin)?_c('v-btn',{staticClass:"arrow-button",class:_vm.isNavigationExpanded ? 'arrow-button-right' : 'arrow-button-left',attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.isNavigationExpanded = !_vm.isNavigationExpanded}}},[(!_vm.isNavigationExpanded)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.isUserLoggedin)?_c('the-layout-drawer',{attrs:{"miniValue":_vm.isNavigationExpanded},on:{"mini":_vm.onNavigationExpand}}):_vm._e(),(_vm.isUserLoggedin)?_c('the-layout-toolbar',{class:_vm.isUserLoggedin
        ? _vm.isNavigationExpanded
          ? 'secured-mini-header'
          : 'secured-expanded-header'
        : ''}):_vm._e(),_c('the-layout-footer'),_c('v-main',{class:_vm.isUserLoggedin
        ? _vm.isNavigationExpanded
          ? 'v-main-secured-mini'
          : 'v-main-secured-expanded'
        : ''},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }