import login from "@/api/login";
import userService from "../../api/user.service";
// import jwt_decode from 'jwt-decode';
// import { v1 as uuidv4 } from "uuid";

const user = {
  state: {
    token: "",
    refreshToken: "",
    tokenExpiryTime: "",
    roles: "",
    user: "",
    name: "",
    userId: "",
    customerId: "",
    customerIds: "",
    toolIds: "",
    referenceId: "",
    reportvalue: "",
    auditFinanceCustomer: "",
    auditFinanceFromDate: "",
    auditFinanceToDate: "",
    selectedTimePeriodForAuditTool:"",
    FinanceCustomerName: "",
    ToolsComName: "",
    ToolsCustomerId: "",
    ToolsapiToken: "",
    AppSheetToolsComName: "",
    AppSheetToolsCustomerId: "",
    AppSheetToolsapiToken: "",
    reportId: "",
    companyName: "",
    supportTicketsReferenceId: "",
    assetWizardValue:"",
    serviceWizardValue:"",
    assetRefreshVal:"",
    serviceRefreshVal:"",
    activeTab:"",
    assetBatchId:"",
    serviceBatchId:"",
    setErrorMessageLogin: "",
    pageNumber:1,
    pageSize:-1,
    sortBy: "",
    sortDesc: [true]
  },
  // this.$store.dispatch("ToolsComName", this.compName);
  // this.$store.dispatch("ToolsCustomerId", this.Id);
  // this.$store.dispatch("ToolsapiToken", this.apiToken);
  getters: {
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    tokenExpiryTime: state => state.tokenExpiryTime,
    roles: state => state.roles,
    name: state => state.name,
    user: state => state.user,
    userId: state => state.userId,
    customerId: state => state.customerId,
    customerIds: state => state.customerIds,
    toolIds: state => state.toolIds,
    referenceId: state => state.referenceId,
    avatar: () => null,
    reportvalue: state => state.reportvalue,
    auditFinanceCustomer: state => state.auditFinanceCustomer,
    auditFinanceFromDate: state => state.auditFinanceFromDate,
    auditFinanceToDate: state => state.auditFinanceToDate,
    selectedTimePeriodForAuditTool: state => state.selectedTimePeriodForAuditTool,
    FinanceCustomerName: state => state.FinanceCustomerName,
    ToolsComName: state => state.ToolsComName,
    ToolsCustomerId: state => state.ToolsCustomerId,
    ToolsapiToken: state => state.ToolsapiToken,
    AppSheetToolsComName: state => state.AppSheetToolsComName,
    AppSheetToolsCustomerId: state => state.AppSheetToolsCustomerId,
    AppSheetToolsapiToken: state => state.AppSheetToolsapiToken,
    reportId: state => state.reportId,
    companyName: state => state.companyName,
    supportTicketsReferenceId: state => state.supportTicketsReferenceId,
    assetWizardValue:state => state.assetWizardValue,
    serviceWizardValue:state => state.serviceWizardValue,
    assetRefreshVal: state => state.assetRefreshVal,
    serviceRefreshVal: state => state.serviceRefreshVal,
    activeTab: state => state.activeTab,
    assetBatchId: state => state.assetBatchId, 
    serviceBatchId: state => state.serviceBatchId,
    setErrorMessageLogin: state => state.setErrorMessageLogin,
    pageNumber: state => state.pageNumber,
    pageSize: (state) => state.pageSize, 
    sortBy: state => state.sortBy,
    sortDesc: (state) => state.sortDesc, 
  },

  mutations: {
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = "";
        (state.refreshToken = ""),
          (state.tokenExpiryTime = ""),
          (state.roles = "");
        state.user = "";
        state.name = "";
        state.userId = "";
        state.customerId = "";
        state.customerIds = "";
        state.toolIds= "";
        state.referenceId = "";
        state.reportvalue = "";
        state.auditFinanceCustomer = "";
        state.auditFinanceFromDate = "";
        state.auditFinanceToDate = "";
        state.selectedTimePeriodForAuditTool = "";
        state.FinanceCustomerName = "";
        state.ToolsComName = "";
        state.ToolsCustomerId = "";
        state.ToolsapiToken = "";
        (state.AppSheetToolsComName = ""),
          (state.AppSheetToolsCustomerId = ""),
          (state.AppSheetToolsapiToken = ""),
          (state.reportId = ""),
          (state.companyName = "");
          state.supportTicketsReferenceId = "";
          state.assetWizardValue = "";
          state.serviceWizardValue = "";
          state.assetRefreshVal = "";
          state.serviceRefreshVal = "";
          state.activeTab = "";
          state.assetBatchId = "";
          state.serviceBatchId = "";
          state.setErrorMessageLogin = "";
          state.pageNumber = "";
          state.pageSize = "";
          state.sortBy = "";
          state.sortDesc = "";
      } else {
        state.token = payload.token || state.token;
        // state.refreshToken = payload.refreshToken || state.refreshToken;
        // state.tokenExpiryTime = payload.tokenExpiryTime || state.tokenExpiryTime;
        state.roles = payload.userRole || state.userRole;
        state.user = payload.username || state.username;
        state.name = payload.flName || state.flName;
        state.userId = payload.userId || state.userId;
        state.customerId = payload.customerId || state.customerId;
        state.customerIds = payload.customerIds || state.customerIds;
        state.toolIds = payload.toolIds || state.toolIds;
        state.referenceId = payload.referenceId || state.referenceId;
        state.reportvalue = payload.reportvalue || state.reportvalue;
        state.auditFinanceCustomer =
          payload.auditFinanceCustomer || state.auditFinanceCustomer;
        state.auditFinanceFromDate =
          payload.auditFinanceFromDate || state.auditFinanceFromDate;
        state.auditFinanceToDate =
          payload.auditFinanceToDate || state.auditFinanceToDate;
          state.selectedTimePeriodForAuditTool = payload.selectedTimePeriodForAuditTool || state.selectedTimePeriodForAuditTool;
        state.FinanceCustomerName =
          payload.FinanceCustomerName || state.FinanceCustomerName;
        state.ToolsComName = payload.ToolsComName || state.ToolsComName;
        state.ToolsCustomerId =
          payload.ToolsCustomerId || state.ToolsCustomerId;
        state.ToolsapiToken = payload.ToolsapiToken || state.ToolsapiToken;
        (state.AppSheetToolsComName =
          payload.AppSheetToolsComName || state.AppSheetToolsComName),
          (state.AppSheetToolsCustomerId =
            payload.AppSheetToolsCustomerId || state.AppSheetToolsCustomerId),
          (state.AppSheetToolsapiToken =
            payload.AppSheetToolsapiToken || state.AppSheetToolsapiToken),
          (state.reportId = payload.reportId || state.reportId),
          (state.companyName = payload.companyName || state.companyName);
          state.supportTicketsReferenceId = payload.supportTicketsReferenceId || state.supportTicketsReferenceId;
          state.assetWizardValue = payload.assetWizardValue || state.assetWizardValue;
          state.serviceWizardValue = payload.serviceWizardValue || state.serviceWizardValue;
          state.assetRefreshVal = payload.assetRefreshVal || state.assetRefreshVal;
          state.serviceRefreshVal = payload.serviceRefreshVal || state.serviceRefreshVal;
          state.activeTab = payload.activeTab || state.activeTab;
          state.assetBatchId = payload.assetBatchId || state.assetBatchId;
          state.serviceBatchId = payload.serviceBatchId || state.serviceBatchId;
          state.setErrorMessageLogin = payload.setErrorMessageLogin || state.setErrorMessageLogin;
          state.pageNumber = payload.pageNumber || state.pageNumber;
          state.pageSize = payload.pageSize || state.pageSize;
          state.sortBy = payload.sortBy || state.sortBy;
          state.sortDesc = payload.sortDesc || state.sortDesc;
      }
    },
    SET_ACCESS_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_TOKEN_EXPIRY_TIME: (state, tokenExpiryTime) => {
      state.tokenExpiryTime = tokenExpiryTime;
    },
    REPORT_VALUE: (state, value) => {
      state.reportvalue = value;
    },
    REFERENCE_ID: (state, value) => {
      state.referenceId = value;
    },
    SET_Customer: (state, value) => {
      state.auditFinanceCustomer = value;
    },
    SET_FromDate: (state, value) => {
      state.auditFinanceFromDate = value;
    },
    SET_ToDate: (state, value) => {
      state.auditFinanceToDate = value;
    },
    SET_TIME_PERIOD: (state, value) => {
      state.selectedTimePeriodForAuditTool = value;
    },
    SET_CUSTOMERNAME: (state, value) => {
      state.FinanceCustomerName = value;
    },
    SET_TOOL_COMNAME: (state, value) => {
      state.ToolsComName = value;
    },
    SET_TOOL_CUSTOMERID: (state, value) => {
      state.ToolsCustomerId = value;
    },
    SET_TOOL_APITOKEN: (state, value) => {
      state.ToolsapiToken = value;
    },
    SET_APPSHEET_COMNAME: (state, value) => {
      state.AppSheetToolsComName = value;
    },
    SET_APPSHEET_CUSTOMERID: (state, value) => {
      state.AppSheetToolsCustomerId = value;
    },
    SET_APPSHEET_APITOKEN: (state, value) => {
      state.AppSheetToolsapiToken = value;
    },
    SET_REPORTID: (state, value) => {
      state.reportId = value;
    },
    SET_COMPANYNAME: (state, value) => {
      state.companyName = value;
    },
    SET_SUPPORT_REF_ID: (state, value) => {
      state.supportTicketsReferenceId = value;
    },
    SET_ASSET_WIZARD_VALUE: (state, value) => {
      state.assetWizardValue = value;
    },
    SET_SERVICE_WIZARD_VALUE: (state, value) => {
      state.serviceWizardValue = value;
    },
    SET_ASSET_REFRESH_VAL: (state, value) => {
      state.assetRefreshVal = value;
    },
    SET_SERVICE_REFRESH_VAL: (state, value) => {
      state.serviceRefreshVal = value;
    },
    SET_ACTIVE_TAB: (state, value) => {
      state.activeTab = value;
    },
    // assetBatchId
    SET_ASSET_BATCH_ID:(state, value) => {
      state.assetBatchId = value;
    },
    // serviceBatchId
    SET_SERVICE_BATCH_ID:(state, value) => {
      state.serviceBatchId = value;
    },
    SET_ERROR_MESSAGE_LOGIN:(state, value) => {
      state.setErrorMessageLogin = value;
    },
    SET_PAGE_NUMBER:(state, value) => {
      state.pageNumber = value;
    },
    SET_PAGE_SIZE: (state, value) => {
      state.pageSize = value;
    },
    SET_SORT_BY:(state, value) => {
      state.sortBy = value;
    },
    SET_SORT_DESC: (state, value) => {
      state.sortDesc = value;
    },
  },

  actions: {
    // Login user dispatch
    LoginByEmail: async ({ commit }, payload) => {
      try {
        const response = await login.loginByEmail(
          payload.username.trim(),
          payload.password
        );
        if (response.isSuccess) {
          if (response.data) {
            await commit("SET_ACCESS_TOKEN", response.data.token.access_token);
            await commit(
              "SET_REFRESH_TOKEN",
              response.data.token.refresh_token
            );
            await commit(
              "SET_TOKEN_EXPIRY_TIME",
              response.data.token.expires_at
            );
            // let token = response.tokenResponse.access_token;
            // let tokenResponse = jwt_decode(token);
            // console.log(tokenResponse["userid"])
            // dispatch('UserInfo', tokenResponse["userid"])
            // .then(() => {
            //   console.log("123")
            // })
            // .catch(
            //   console.log("error")
            // );
            // if (JSON.stringify(response.userInformation) !== "{}") {
            //   // response.userInformation.access_token = uuidv4();
            //   response.userInformation.flName =
            //     response.userInformation.firstName +
            //     " " +
            //     response.userInformation.lastName;
            //   await commit("SET_USER_INFO", response.userInformation);

            //   ////set default customer values to store
            //   await commit("SET_REPORTID", response.defaultCompanyId);
            //   await commit("SET_COMPANYNAME", response.defaultCompanyName);
            //   await commit("REFERENCE_ID", response.defaultCompanyId);
            //   await commit("SET_SUPPORT_REF_ID", response.userInformation.supportReferenceId);
            //   // await commit("SET_REPORTID", null);
            //   // await commit("SET_COMPANYNAME", null);
            //   // await commit("REFERENCE_ID", null);
            //   return true;
            // } else {
            //   return false;
            // }
            return true;
          } else {
            return false;
          }
        }
      } catch (err) {
        console.warn("[vuex.user] LoginByEmail", err);
      }
    },
    // Login user dispatch
    UserInfo: async ({ commit }, payload) => {
      try {
        const response = await userService.getUser(
          payload.userId
        );
        if (response.isSuccess) {
          if (response.data && JSON.stringify(response.data) !== "{}") {
            // response.userInformation.access_token = uuidv4();
            response.data.flName =
              response.data.firstName +
              " " +
              response.data.lastName;
            response.data.username = response.data.email;
            response.data.userId = response.data.id;
            for (var a=0; a<response.data.userMappings.length; a++) {
              if (response.data.userMappings[a].role["name"].split("-")[0] == 'ProServ') {
                
                response.data["userRole"] = response.data.userMappings[a].role["name"].split("-")[1];
                // only show this selected customers in all customers dropdown?
                response.data["customerIds"] = response.data.userMappings[a]["customerIds"] ? response.data.userMappings[a]["customerIds"].split(",") : "";
                // only show this selected tools in all customers dropdown?
                response.data["toolIds"] = response.data.userMappings[a]["toolIds"] ? response.data.userMappings[a]["toolIds"].split(",") : "";
              } 
            }
            await commit("SET_USER_INFO", response.data);

            ////set default customer values to store
            await commit("SET_REPORTID", response.data.defaultCustomerId);
            await commit("SET_COMPANYNAME", response.data.defaultCustomerName);
            await commit("REFERENCE_ID", response.data.defaultCustomerId);
            await commit("SET_SUPPORT_REF_ID", response.data.supportReferenceId);
            // await commit("SET_REPORTID", null);
            // await commit("SET_COMPANYNAME", null);
            // await commit("REFERENCE_ID", null);

            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (err) {
        console.warn("[vuex.user] UserInfo", err);
        return false;
      }
    },
    // Login user dispatch
    getAuthToken: async ({ commit }, payload) => {
      try {
        const response = await login.getAuthToken(
          payload.token
        );
        if (response.isSuccess) {
          
          if (response.data) {
            await commit("SET_ACCESS_TOKEN", response.data.token.access_token);
            await commit(
              "SET_REFRESH_TOKEN",
              response.data.token.refresh_token
            );
            await commit(
              "SET_TOKEN_EXPIRY_TIME",
              response.data.token.expires_at
            );
            if (response.data.user && JSON.stringify(response.data.user) !== "{}") {
              // response.userInformation.access_token = uuidv4();
              response.data.user.flName =
              response.data.user.firstName +
                " " +
                response.data.user.lastName;
                response.data.user.username = response.data.user.email;
                response.data.user.userId = response.data.user.id;
              for (var a=0; a<response.data.user.userMappings.length; a++) {
                if (response.data.user.userMappings[a].role["name"].split("-")[0] == 'ProServ') {
                  
                  response.data.user["userRole"] = response.data.user.userMappings[a].role["name"].split("-")[1];
                  // only show this selected customers in all customers dropdown?
                  response.data.user["customerIds"] = response.data.user.userMappings[a]["customerIds"] ? response.data.user.userMappings[a]["customerIds"].split(",") : "";
                  // only show this selected tools in all customers dropdown?
                  response.data.user["toolIds"] = response.data.user.userMappings[a]["toolIds"] ? response.data.user.userMappings[a]["toolIds"].split(",") : "";
                } 
              }
              await commit("SET_USER_INFO", response.data.user);
  
              ////set default customer values to store
              await commit("SET_REPORTID", response.data.user.defaultCustomerId);
              await commit("SET_COMPANYNAME", response.data.user.defaultCustomerName);
              await commit("REFERENCE_ID", response.data.user.defaultCustomerId);
              await commit("SET_SUPPORT_REF_ID", response.data.user.supportReferenceId);
              await commit("SET_ERROR_MESSAGE_LOGIN", "");
              // await commit("SET_REPORTID", null);
              // await commit("SET_COMPANYNAME", null);
              // await commit("REFERENCE_ID", null);
  
              return true;
            } else {
              await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
            }
            // let token = response.tokenResponse.access_token;
            // let tokenResponse = jwt_decode(token);
            // console.log(tokenResponse["userid"])
            // dispatch('UserInfo', tokenResponse["userid"])
            // .then(() => {
            //   console.log("123")
            // })
            // .catch(
            //   console.log("error")
            // );
            // if (JSON.stringify(response.userInformation) !== "{}") {
            //   // response.userInformation.access_token = uuidv4();
            //   response.userInformation.flName =
            //     response.userInformation.firstName +
            //     " " +
            //     response.userInformation.lastName;
            //   await commit("SET_USER_INFO", response.userInformation);

            //   ////set default customer values to store
            //   await commit("SET_REPORTID", response.defaultCompanyId);
            //   await commit("SET_COMPANYNAME", response.defaultCompanyName);
            //   await commit("REFERENCE_ID", response.defaultCompanyId);
            //   await commit("SET_SUPPORT_REF_ID", response.userInformation.supportReferenceId);
            //   // await commit("SET_REPORTID", null);
            //   // await commit("SET_COMPANYNAME", null);
            //   // await commit("REFERENCE_ID", null);
            //   return true;
            // } else {
            //   return false;
            // }
            // return true;
          } else {
            await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
              return false;
          }
        } else {
          await commit("SET_ERROR_MESSAGE_LOGIN", response.systemMessage);
            return false;
        }
      } catch (err) {
        console.warn("[vuex.user] getAuthToken", err);
      }
    },
    Reportvalue: async (context, value) => {
      context.commit("REPORT_VALUE", value);
    },
    ReferenceIdValue: async ({ commit }, value) => {
      commit("REFERENCE_ID", value);
    },
    setCustomer: async (context, value) => {
      context.commit("SET_Customer", value);
    },
    setFromDate: async (context, value) => {
      context.commit("SET_FromDate", value);
    },
    setToDate: async (context, value) => {
      context.commit("SET_ToDate", value);
    },
    setTimePeriod: async (context, value) => {
      context.commit("SET_TIME_PERIOD", value);
    },
    setCustomerName: async (context, value) => {
      context.commit("SET_CUSTOMERNAME", value);
    },
    setToolCustomerName: async (context, value) => {
      context.commit("SET_TOOL_COMNAME", value);
    },
    setToolCustomerId: async (context, value) => {
      context.commit("SET_TOOL_CUSTOMERID", value);
    },
    setToolapiToken: async (context, value) => {
      context.commit("SET_TOOL_APITOKEN", value);
    },
    setAppSheetCustomerName: async (context, value) => {
      context.commit("SET_APPSHEET_COMNAME", value);
    },
    setAppSheetCustomerId: async (context, value) => {
      context.commit("SET_APPSHEET_CUSTOMERID", value);
    },
    setAppSheetapiToken: async (context, value) => {
      context.commit("SET_APPSHEET_APITOKEN", value);
    },
    setReportId: async (context, value) => {
      context.commit("SET_REPORTID", value);
    },
    setCompanyName: async (context, value) => {
      context.commit("SET_COMPANYNAME", value);
    },
    setSupportRefId: async (context, value) => {
      context.commit("SET_SUPPORT_REF_ID", value);
    },
    setAssetWizardValue: async (context, value) => {
      context.commit("SET_ASSET_WIZARD_VALUE", value);
    },
    setServiceWizardValue: async (context, value) => {
      context.commit("SET_SERVICE_WIZARD_VALUE", value);
    },
    setAssetRefreshVal: async (context, value) => {
      context.commit("SET_ASSET_REFRESH_VAL", value);
    },
    setServiceRefreshVal: async (context, value) => {
      context.commit("SET_SERVICE_REFRESH_VAL", value);
  },
  setActiveTabVal:async (context, value) => {
    context.commit("SET_ACTIVE_TAB", value);
  },
  setAssetBatchId:async (context, value) => {
    context.commit("SET_ASSET_BATCH_ID", value);
  },
  setServiceBatchId:async (context, value) => {
    context.commit("SET_SERVICE_BATCH_ID",value);
  },
  setErrorMessageLogin:async (context, value) => {
    context.commit("SET_ERROR_MESSAGE_LOGIN",value);
  },
  setPageNumber:async (context, value) => {
    context.commit("SET_PAGE_NUMBER",value);
  },
  setPageSize: async (context, value) => {
    context.commit("SET_PAGE_SIZE", value);
  },
  setSortBy:async (context, value) => {
    context.commit("SET_SORT_BY",value);
  },
  setSortDesc: async (context, value) => {
    context.commit("SET_SORT_DESC", value);
  },
    GetUserInfo: async () => {
      try {
        const response = false;

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response) {
          throw new Error("Verification failed, please login again.");
        }

        // Verify returned roles are a non-null array
        if (response.user.roles && response.user.roles.length === 0) {
          throw new Error("getInfo: roles must be a non-null array!");
        }

        //commit('SET_USER_INFO', response.user);
      } catch (err) {
        console.warn("[vuex.user] GetUserInfo", err);
      }
    },

    LogOut: async ({ commit }) => {
      try {
        await commit("SET_USER_INFO", { logout: true });
        localStorage.clear();
      } catch (err) {
        console.warn("[vuex.user] LogOut", err);
      }
    }
  }
};

export default user;
