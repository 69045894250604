import Vue from "vue";
import VueRouter from "vue-router";
import authRouter from "./modules/auth";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/",
        alias: "/",
        component: () => import("@/components/Dashboard/index"),
        name: "Dashboard",
        meta: {
          title: "Home",
          // roles: ['Admin',"install admin", "sales"],
          icon: "mdi-home-outline",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/",
    name: "User Management",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/user-management/add",
        component: () => import("@/components/NewUserManagement/index"),
        name: "user-management",
        meta: {
          title: "Add User",
          roles: ["Admin", "Developers", "Support Admin"],
        },
      },
      {
        path: "/user-management/list",
        component: () => import("@/components/NewUsersList/index"),
        name: "UsersList",
        meta: {
          roles: ["Admin", "Developers", "Support Admin"],
        },
      },
      {
        path: "/user-management/edit/:id",
        component: () => import("@/components/NewUserManagement/index"),
        name: "EditUser",
        meta: {
          title: "Edit User",
          roles: ["Admin", "Developers", "Support Admin"],
        },
      },
    ],
  },
  {
    path: "/",
    alias: "/customer-onboarding",
    // redirect: "/customers",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    name: "Customers",
    // meta: {
    //   title: "Customers",
    // },
    children: [
      {
        path: "/customers",
        component: () =>
          import("@/components/UserOnboarding/UserOnboarding/index"),
        name: "CustomerOnboardingList",
        meta: {
          roles: ["Admin", "Manager", "User"],
        },
      },
      {
        path: "/customer-onboarding/view/:id",
        component: () =>
          import("@/components/UserOnboarding/ViewUserOnboarding/index"),
        name: "ViewUserOnboarding",
        meta: {
          title: "View",
          roles: ["Admin", "Manager", "User"],
        },
      },
      {
        path: "/customer-deatils/",
        component: () =>
          import("@/components/UserOnboarding/ViewUserOnboarding/index"),
        name: "customer-deatils",
        meta: {
          title: "View",
          roles: ["Admin", "Manager", "User"],
        },
      },
    ],
  },

  {
    path: "/",
    // alias: "/profile",
    name: "Support",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/support-tickets",
        component: () => import("@/components/Support/SupportTickets/index"),
        name: "Support",
        meta: {
          title: "Support",
          icon: "mdi-help",
          affix: true,
        },
      }
    ],
  },
  {
    path: "/",
    // alias: "/profile",
    name: "Profile",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/user-profile",
        component: () => import("@/components/Profile/index"),
        name: "profile",
        meta: {
          title: "Profile",
          icon: "mdi-view-dashboard-outline",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/",
    alias: "/apiclients",
    name: "API Clients",
    component: { render: (c) => c("router-view") },
    children: [
      {
        path: "/apiclients",
        component: () => import("@/components/APIClients/index"),
        name: "API Clients",
        meta: {
          icon: "mdi-human-greeting-proximity",
        },
      },
    ],
  },

  {
    path: "/clients-list",
    // redirect: "/clients-list",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    name: "Report Mappings",
    // meta: {
    //   title: "Report Mappings",
    // },
    children: [
      {
        path: "/new-client",
        component: () => import("@/components/RegisterClient/NewClient/index"),
        name: "New Client",
        meta: {
          title: "New Client",
          roles: ["Admin"],
        },
      },
      {
        path: "/view-client/:id",
        component: () => import("@/components/RegisterClient/ViewClient/index"),
        name: "View Client",
        meta: {
          title: "View Client",
          roles: ["Admin"],
        },
      },
      {
        path: "/clients-list",
        component: () =>
          import("@/components/RegisterClient/RegisteredClientsList/index"),
        meta: {
          roles: ["Admin"],
        },
      },
    ],
  },
  {
    path: "/",
    alias: "/data-integrity-report-dashboard",
    name: "Data Integrity Dashboard",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/data-integrity-report-dashboard",
        name: "Data Integrity Dashboard",
        component: () =>
          import(
            "@/components/DataIntegrityReport/DataIntegrityDashboard/index"
          ),
      },
      {
        path: "/data-integrity-report",
        name: "Data Integrity Report",
        component: () =>
          import("@/components/DataIntegrityReport/DataIntegrityReport/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/audit-tool",
    name: "unit 360",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/audit-tool",
        name: "Unit 360",
        component: () => import("@/components/AuditTool/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/field_tool_management",
    name: "Field Install Tool",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/field_tool_management",
        name: "Field Install Tool",
        component: () =>
          import("@/components/AppSheetToolsSection/FieldToolManagement/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/certify-install",
    name: "Certify Install",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/certify-install",
        name: "Certify Install",
        component: () =>
          import("@/components/AppSheetToolsSection/CertifyInstall/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/group-assignment-tool",
    name: "Group Assignment",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/group-assignment-tool",
        name: "Group Assignment Tool",
        component: () =>
          import("@/components/SupportToolsSection/GroupAssignmentTool/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/hours-update-tool",
    name: "Hours Update Tool",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/hours-update-tool",
        name: "Hours Update Tool",
        component: () =>
          import("@/components/SupportToolsSection/HoursUpdateTool/index"),
      },
    ],
  },
  {
    path: "/",
    alias: "/metadata-update-tool",
    name: "Metadata Update Tool",
    component: { render: (c) => c("router-view") },
    // beforeEnter: validateAccess,
    children: [
      {
        path: "/metadata-update-tool",
        name: "Metadata Update Tool",
        component: () =>
          import("@/components/SupportToolsSection/MetadataUpdateTool/index"),
      },
    ],
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/widget/AppError.vue"),
  },

  ...authRouter,
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

export const asyncRoutes = [
  { path: "*", redirect: "/error/404", hidden: true },
];
