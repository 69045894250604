<template>
  <div>
    <v-menu
      ref="menu"
      v-model="dropdownOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="model"
      transition="scale-transition"
      offset-y
      max-width="300px"
      min-width="300px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-mask="'##/##/####'" 
          v-model="dateModelFormatted" 
          @blur="dateModel = parseDate(dateModelFormatted, true)"
          label="Date (mm/dd/yyyy)"
          prepend-icon="mdi-calendar"
          v-on="on"
          :rules="rules.required"
          outlined
          dense
          v-if="isDateModelShowMask"
        ></v-text-field>
        <v-text-field
          v-mask="'##/##/####'" 
          v-model="dateModelFormatted" 
          @blur="dateModel = parseDate(dateModelFormatted, true)"
          label="Date (mm/dd/yyyy)"
          prepend-icon="mdi-calendar"
          v-on="on"
          :rules="rules.required"
          outlined
          dense
          v-else
        ></v-text-field>
      </template>

      <div class="v-date-time-widget-container">
        <v-layout row wrap>
          <!-- <v-col> -->
            <v-date-picker
            no-title
              v-model="dateModel" @input= "dropdownOpen = false"
              color="red"
              :allowed-dates="allowed"
            ></v-date-picker>
          <!-- </v-col> -->
          <!-- <v-col cols="12" sm="6"> -->
            <!-- <v-btn fab
              small
              :color="getMeridiamButtonColor('AM')"
              class="btn-am"
              @click="meridiam = 'AM'"
              >AM</v-btn
            >

            <v-btn fab
              small
              :color="getMeridiamButtonColor('PM')"
              class="btn-pm"
              @click="meridiam = 'PM'"
              >PM</v-btn
            > -->
<!-- 
            <v-time-picker
              v-if="dropdownOpen"
              v-model="timeModel"
              color="red"
              width="240"
              :no-title="true"
            ></v-time-picker> -->
          <!-- </v-col> -->

          <!-- <v-col cols="12" class="text-center">
            <v-btn small class="mr-4" @click="dropdownOpen = false">Cancel</v-btn>
            <v-btn  small class="btn-submit" @click="confirm()">
              Ok
            </v-btn>
          
          </v-col> -->
        </v-layout>
      </div>
    </v-menu>
  </div>
</template>
<style scoped>
.v-date-time-widget-container {
  background: white;
  padding: 15px;
}

.v-card {
  box-shadow: none;
}

.btn-am {
  float: left;
}

.btn-pm {
  float: right;
}
.v-picker__title__btn {
  display: none !important;
}

.v-date-picker-table {
  height: auto;
}
</style>
<script>
//import moment from "moment";
import rules from "../utils/rules"
export default {
  name: "vue-date-time",
  props: ["value", "dateTime", "required","allowedDates"],
  data() {
    return {
      dropdownOpen: false,
      meridiam: "AM",
      displayDate: this.dateTime,
      dateModel: this.dateTime,
      dateModelFormatted: this.formatDateNew(this.dateTime),
      allowed: this.allowedDates,
      isDateModelShowMask: true,
      timeModel: "",
      rules: this.required ? rules : '',
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  watch: {
    dateModel (val) {
      this.dateModelFormatted = this.formatDateNew(val)
      this.isDateModelShowMask = false;
      setTimeout(() => {
        this.isDateModelShowMask = true;
      }, 1);
      this.confirm();
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(model) {
        console.log("model", model);
      },
    },
    currentSelection() {
      let selectedTime = this.timeModel + " " + this.meridiam;
      return this.formatDate(this.dateModel) + " " + selectedTime;
    },
  },

  methods: {
    formatDateNew (date) {
      if (!date) return ""

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date, isDateModel) {
      if (!date) return ""

      const [month, day, year] = date.split('/')
      if (!month || month<1 || month>12 || !day ||day<1 || day>31 || !year || year<2000 || year>2100) {
        if (isDateModel) {
          this.dateModel = "";
          this.dateModelFormatted = "";
          return ""
        }
      } else {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }

    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      let monthName = this.monthNames[parseInt(month)];
      return `${monthName} ${day}, ${year}`;
    },

    // Confirm the datetime selection and close the popover
    confirm() {
      this.onUpdateDate();
      // this.dropdownOpen = false;
    },

    // Format the date and trigger the input event
    onUpdateDate() {
      if (!this.dateModel) return false;

      // let selectedTime = this.timeModel + " " + this.meridiam;
    //   let convertDate = this.formatDate(this.dateModel) + " " + selectedTime;
    //   console.log("convertDate", convertDate)
      this.displayDate = this.dateModel;
      this.$emit("input", this.dateModel);
    },

    // Set the active state for the meridiam buttons
    getMeridiamButtonColor(m) {
      if (m === this.meridiam) {
        return "red";
      } else {
        return "darkgray";
      }
    },
  },

  mounted() {
  },
};
</script>
