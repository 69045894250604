// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.APPSHEET_TOOLS_ENDPOINT,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios
      .appSheetToolsEndPoint({
        method,
        url: resource,
        data
      })
      .then(req => {
        return req.data;
      });
  },
  async executeDownloadPDF(method, resource, data) {
    return axios
      .appSheetToolsEndPoint({
        method,
        url: resource,
        data,
        responseType: "blob"
      })
      .then(req => {
        return req.data;
      });
  },
  getAllCustomers(){
    return this.execute('get', '/Company');
  },
  getAllVendors() {
    return this.execute("get", `/MasterData/Vendors`);
  },
  getCutomersList() {
    return this.execute("get", `/AppSheet/Customers`);
  },
  FileUpload(customerId, payload) {
    return this.execute("post", `/AppSheet/${customerId}`, payload);
  },
  CustomerAssetsList(customerId) {
    return this.execute("get", `/AppSheet/Logs/${customerId}`);
  },
  GetViewLogs(toolId, customerId) {
    return this.execute(
      "get",
      `/AppSheet/Log/${toolId}?customerId=${customerId}`
    );
  },
  // ExecuteTools(payload){
  //   return this.execute("put", `/AppSheet/Execute`,payload);
  // },
  // CustomerAssetsList(customerId) {
  //   return this.execute("get", `/AppSheet/Logs/${customerId}`);
  // },
  // GetViewLogs(toolId, customerId) {
  //   return this.execute(
  //     "get",
  //     `/AppSheet/Log/${toolId}?customerId=${customerId}`
  //   );
  // },
  ExecuteTools(payload) {
    return this.execute("put", `/AppSheet/Execute`, payload);
  },
  getBrands() {
    return this.execute("get", "/AppSheet/Brands");
  },
  getMachines() {
    return this.execute("get", "/AppSheet/Machines");
  },
  getListOfErrors(toolId) {
    return this.execute("get", `/AppSheet/Errors/${toolId}`);
  },
  UpdateBrands(payload) {
    return this.execute("put", `/AppSheet/FixErrors`, payload);
  },
  PostExceptions(payload) {
    return this.execute("post", `AppSheet/AddToException`, payload);
  },
  getFixMapsData() {
    return this.execute("get", `/AppSheet/Mappings`);
  },
  UpdateExistingMappings(payload) {
    return this.execute("put", "/AppSheet/Mappings", payload);
  },
  DeleteFixMappings(payload) {
    return this.execute("delete", "/AppSheet/Mappings", payload);
  },
  CreateCustomer(payload) {
    return this.execute("post", "AppSheet/Customer", payload);
  },
  getSericeTypedropdown() {
    return this.execute("get", "AppSheet/ServiceType");
  },
  getListOfServices(LogId) {
    return this.execute("get", `/AppSheet/ServiceTypeError/${LogId}`);
  },
  UpdateServiceMappings(payload) {
    return this.execute("put", "/AppSheet/ServiceTypeMapping", payload);
  },
  DeleteLogs(toolId) {
    return this.execute("delete", `/AppSheet/Log/${toolId}`);
  },
  // UpdateServiceMappings(payload) {
  //   return this.execute("put", "/AppSheet/ServiceTypeMapping", payload);
  // },
  getNotes(serviceID) {
    return this.execute("GET", `/Notes/${serviceID}`);
  },
  getServiceById(serviceID, userId) {
    return this.execute("GET", `/Search/${serviceID}?userId=${userId}`);
  },
  getServiceOtherPictures(assetID, serviceID) {
    return this.execute(
      "GET",
      `/Search/asset/${assetID}/serviceimages?serviceId=${serviceID}`
    );
  },
  getInspectionReport(payload) {
    return this.executeDownloadPDF(
      "post",
      `/Search/service/inspectionreport`,
      payload
    );
  },
  updateNote(noteID, updateNotePayLoad) {
    return this.execute("PUT", `/Notes/${noteID}`, updateNotePayLoad);
  },
  deleteNote(noteID, updatedBy) {
    return this.execute("DELETE", `/Notes/${noteID}?updatedBy=${updatedBy}`);
  },
  addNote(addNotePayLoad) {
    return this.execute("POST", "/Notes", addNotePayLoad);
  },
  getAudits(
    searchAudit,
    SelectedCustomer,
    fromDate,
    toDate,
    serviceType,
    serviceStatus,
    userId,
    bookmark,
    manufacturer,
    contractor,
    technician,
    source,
    branchId
  ) {
    return this.execute(
      "get",
      `/Search?query=${searchAudit}&customerId=${SelectedCustomer}&fromDate=${fromDate}&toDate=${toDate}&serviceType=${serviceType}&serviceStatus=${serviceStatus}&userId=${userId}&bookmark=${bookmark}&manufaturer=${manufacturer}&contractor=${contractor}&technician=${technician}&source=${source}&branchId=${branchId}`
    );
  },
  getAuditServiceTypes() {
    return this.execute("GET", `/Search/servicetypes`);
  },
  createFavourite(payload) {
    return this.execute("POST", "/Bookmarks", payload);
  },
  sendEmailAuditTool(payload) {
    return this.execute("POST", "/Notification/audittool", payload);
  },
  EmailHistory(type, serviceId, fromdate, todate) {
    return this.execute(
      "GET",
      `/Notification/${type}/history?id=${serviceId}&fromdate=${fromdate}&todate=${todate}`
    );
  },
  getEmailAttachments(itemId, fileId) {
    return this.execute(
      "GET",
      `/Notification/${itemId}/attachment?attachmentId=${fileId}`
    );
  },
  //Service List Upload
  ServiceFileUpload(customerId, payload) {
    return this.execute(
      "post",
      `/TrackunitServiceUpload/${customerId}`,
      payload
    );
  },
  DeleteServiceLogs(toolId) {
    return this.execute("delete", `/TrackunitServiceUpload/Log/${toolId}`);
  },
  ExecuteServiceTools(payload) {
    return this.execute("put", `/TrackunitServiceUpload/Execute`, payload);
  },
  GetViewServiceLogs(toolId, customerId) {
    return this.execute(
      "get",
      `/TrackunitServiceUpload/Log/${toolId}?customerId=${customerId}`
    );
  },
  CustomerServiceList(customerId) {
    return this.execute("get", `/TrackunitServiceUpload/Logs/${customerId}`);
  },
  getInstallationsChart(fromDate, toDate,reportId) {
    return this.execute(
      "get",
      `stats/installations?fromDate=${fromDate}&toDate=${toDate}&customerId=${reportId}`
    );
  },
  CheckToolAvailable(CRId,toolType){
    return this.execute("get", `/ToolAccess/${CRId}/${toolType}`);
  },

   //Service List upload for Customer
   CustomerServiceListForCustomer(customerId) {
    return this.execute("get", `/Customer/Logs/${customerId}`);
  },
  ServiceFileUploadForCustomer(customerId, payload) {
    return this.execute("post", `/Customer/${customerId}`, payload);
  },
  GetViewServiceLogsForCustomer(toolId, customerId) {
    return this.execute(
      "get",
      `/Customer/Log/${toolId}?customerId=${customerId}`
    );
  },
  ExecuteServiceToolsForCustomer(payload) {
    return this.execute("put", `/Customer/Execute`, payload);
  },
  DeleteServiceLogsForCustomer(toolId) {
    return this.execute("delete", `/AppSheetServices/Log/${toolId}`);
  },
  //Service List upload for Customer

  //Service List Upload Vendor

  ServiceFileUploadVendor(vendorId, payload) {
    return this.execute("post", `/Vendor/${vendorId}`, payload);
  },
  VendorServiceList(vendorId) {
    return this.execute("get", `/Vendor/Logs/${vendorId}`);
  },
  GetViewServiceLogsVendor(toolId, vendorId) {
    return this.execute("get", `/Vendor/Log/${toolId}?customerId=${vendorId}`);
  },
  ExecuteServiceToolsVendor(payload) {
    return this.execute("put", `/Vendor/Execute`, payload);
  },

  //Service List Upload Vendor
  //master table
  getMachinesData() {
    return this.execute("get", `/MachineTypes`);
  },
  getMachineDataById(machineId) {
    return this.execute("get", `/MachineTypes/${machineId}`);
  },
  updateMachineData(machineId, payload) {
    return this.execute("put", `/MachineTypes/${machineId}`, payload);
  },
  createMachineData(payload) {
    return this.execute("post", `/MachineTypes`, payload);
  },
  getServicesData() {
    return this.execute("get", `/ServiceTypes`);
  },
  getServiceDataById(serviceId) {
    return this.execute("get", `/ServiceTypes/${serviceId}`);
  },
  updateServiceData(serviceId, payload) {
    return this.execute("put", `/ServiceTypes/${serviceId}`, payload);
  },
  createServiceData(payload) {
    return this.execute("post", `/ServiceTypes`, payload);
  },
  getDevicesData() {
    return this.execute("get", `/Devices`);
  },
  getDeviceDataById(deviceId) {
    return this.execute("get", `/Devices/${deviceId}`);
  },
  updateDeviceData(deviceId, payload) {
    return this.execute("put", `/Devices/${deviceId}`, payload);
  },
  createDeviceData(payload) {
    return this.execute("post", `/Devices`, payload);
  },
  getBrandsData() {
    return this.execute("get", `/Manufactures`);
  },
  getBrandDataById(brandId) {
    return this.execute("get", `/Manufactures/${brandId}`);
  },
  updateBrandData(brandId, payload) {
    return this.execute("put", `/Manufactures/${brandId}`, payload);
  },
  createBrandData(payload) {
    return this.execute("post", `/Manufactures`, payload);
  },
  getCompaniesData() {
    return this.execute("get", `/Company`);
  },
  getCompanyDataById(id) {
    return this.execute("get", `/Company/${id}`);
  },
  updateCompanyData(id, payload) {
    return this.execute("put", `/Company/${id}`, payload);
  },
  createCompanyData(payload) {
    return this.execute("post", `/Company`, payload);
  },
  updateSafetyRegulations(id,payload){
    return this.execute("put", `/Company/SafetyRegulations/${id}`, payload);
  },
  updateDeploymentInstructionsData(id,payload){
    return this.execute("put", `/Company/DeploymentInstructions/${id}`, payload);
  },
  updatecustomerLogo(id,payload){
    return this.execute("put", `/Company/CustomerLogo/${id}`, payload);
  },
  // Contractor Onboarding 
  getContractorsData(){
    return this.execute("get", `/Contractor`);
  },
  getContractorDataById(contractorId){
    return this.execute("get", `/Contractor/${contractorId}`);
  },
  
  createContractorData(userId,payload){
    return this.execute("post", `/Contractor?createdBy=${userId}`,payload);
  },
  updateContractorData(contractorId,userId,payload){
    return this.execute("put", `/Contractor/${contractorId}?modifiedBy=${userId}`,payload);
  },
  //Filed Tool Management Asset Management
  getAssetManagementList(companyId, duplicateMachine, withoutService, otherMachine, otherBrand,pageNumber,pageSize,searchValue,brand,machineType,sortBy,sortAsc){
    return this.execute("get", `/AssetTool/Assets?companyId=${companyId}&duplicateMachine=${duplicateMachine}&withoutService=${withoutService}&otherMachine=${otherMachine}&otherBrand=${otherBrand}&pageNumber=${pageNumber}&pageSize=${pageSize}&filterSerial=${searchValue}&brand=${brand}&machineType=${machineType}&sortColumn=${sortBy}&sortAsc=${sortAsc}`);
  },
  getAssetRecordById(Id){
    return this.execute("get", `/AssetTool/Assets/${Id}`);
  },
  updateAssetManagementRecord(Id,payload){
    return this.execute("put", `/AssetTool/Assets/${Id}`,payload);
  },
  //summary for both asset & service 
  getFiledToolLogSummary(companyId){
    return this.execute("get", `/FieldToolLog/Summary/${companyId}`);
  },
  cancelBatchProcess(batchId,payload){
    return this.execute("put", `/AssetTool/Batch/${batchId}`,payload);
  },
  //Data Checks Brand && Machine && Service Mapping API's
  getAssetBrandsList(batchId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Map?type=Brand`);
  },
  getAssetMachinesList(batchId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Map?type=Machine`);
  },
  getAssetServicesList(batchId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Map?type=Service`);
  },
  MapAssetBrands(batchId,payload){
    return this.execute("post", `/AssetTool/Batch/${batchId}/Map/Brand`,payload);
  },
  MapAssetMachines(batchId,payload){
    return this.execute("post", `/AssetTool/Batch/${batchId}/Map/Machine`,payload);
  },
  MapAssetServices(batchId,payload){
    return this.execute("post", `/AssetTool/Batch/${batchId}/Map/Service`,payload);
  },
  getMasterBrandsList(){
    return this.execute("get", `/MasterData/Brands`);
  },
  getMasterMachinesList(){
    return this.execute("get", `/MasterData/MachineTypes`);
  },
  getMasterServicesList(){
    return this.execute("get", `/ServiceTypes`);
  },
  // fix summary data popup for brand global mapping
  getFieldToolErrorsList(CRId,errorType){
    return this.execute("get", `/AssetTool/Errors/${CRId}?type=${errorType}`);
  },
  MapSummaryBrandMachine(CRId,payload){
    return this.execute("put", `/AssetTool/FixErrors/${CRId}`,payload);
  },
  //Start Batch Process APIS
  // get Batch Id
  loadBatchId(payload){
    return this.execute("post", `/AssetTool/Batch`,payload);
  },
  getBatchStatus(batchId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Status`);
  },
  //Download Original File
  getOriginalFile(batchId,fileOriginal){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Download?file=${fileOriginal}`);
  },
  //load batch summary
  getBatchSummary(batchId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Summary`);
  },
  //Commit Asset Batch
  batchCommitForAsset(batchId,paylaod){
    return this.execute("post", `/AssetTool/Batch/${batchId}/Commit`,paylaod);
  },
  getLogsData(companyId, tool) {
    return this.execute("get", `/FieldToolLog/all/?companyId=${companyId}&tool=${tool}`);
  },
  GetAssetViewLogs(batchId) {
    return this.execute("get", `/FieldToolLog/asset/${batchId}`);
  },
  getServiceLogsData(batchId) {
    return this.execute("get", `/FieldToolLog/service/${batchId}`);
  },
  downloadOriginalProcessFromAPI(batchId, fileType) {
    return this.execute("get", `/AssetTool/Batch/${batchId}/Download?file=${fileType}`);
  },
  downloadRawFileFromAPI(batchId, fileType, rawFileId){
    return this.execute("get", `/AssetTool/Batch/${batchId}/Download?file=${fileType}&rawFileId=${rawFileId}`);
  },
  downloadServiceOriginalProcessFromAPI(batchId, fileType) {
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Download?file=${fileType}`);
  },
  getDataChecksInformation(batchId) {
    return this.execute("get", `/AssetTool/Batch/${batchId}/Status`);
  },
  getServiceDataChecksInformation(batchId) {
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Status`);
  },
  getSummaryInformation(batchId) {
    return this.execute("get", `/AssetTool/Batch/${batchId}/Summary`);
  },
  getServiceSummaryInformation(batchId) {
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Summary`);
  },

  // Service management Tool
  //load main table 
    getServiceManagementList(companyId, withoutService,pageNumber,pageSize,searchValue,serviceType,serviceStatus,sortBy,sortAsc,contractor){
      return this.execute("get", `/ServiceTool/Services?companyId=${companyId}&withoutService=${withoutService}&pageNumber=${pageNumber}&pageSize=${pageSize}&filterSerial=${searchValue}&serviceType=${serviceType}&serviceStatus=${serviceStatus}&sortColumn=${sortBy}&sortAsc=${sortAsc}&contractor=${contractor}`);
  },
  // edit inline 
  getServiceRecordById(serviceId){
    return this.execute("get", `/ServiceTool/Services/${serviceId}`);
  },
  // update inline 
   updateServiceManagementRecord(Id,payload){
   return this.execute("put", `/ServiceTool/Services/${Id}`,payload);
  },
    //Start Batch Process APIS
  // get Batch Id
  loadBatchIdForService(payload){
    return this.execute("post", `/ServiceTool/Batch`,payload);
  },
  // batch Status for service 
  getBatchStatusForService(batchId){
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Status`);
  },
  //Download Original or Processed by passing file type
  getOriginalFileForservice(batchId,fileOriginal){
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Download?file=${fileOriginal}`);
  },
  //load batch summary
  getBatchSummaryForService(batchId){
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Summary`);
  },
  //Commit Service Batch
  batchCommitForService(batchId,paylaod){
    return this.execute("post", `/ServiceTool/Batch/${batchId}/Commit`,paylaod);
  },
  //Cancel Batch Process
  cancelBatchProcessForService(batchId,payload){
    return this.execute("put", `/ServiceTool/Batch/${batchId}`,payload);
  },

   //Data Checks for get all Service Mapping API's for Service management Tool when open dialog
  getServiceServicesList(batchId){
    return this.execute("get", `/ServiceTool/Batch/${batchId}/Map?type=Service`);
  },
  // save Service Mappings  for service tool
  MapServiceServices(batchId,payload){
    return this.execute("post", `/ServiceTool/Batch/${batchId}/Map/Service`,payload);
  },
  readCertifiedData(serviceId){
    return this.execute("get", `/CertifyInstall/${serviceId}`);
  },
  getCertifyInstallData(companyId,sortBy){
    return this.execute("get", `/CertifyInstall/company/${companyId}?filter=${sortBy}`);
  },
  // CertifyInstall/trackunit?userName=1
  saveCerifyInstall(userName,payload){
    return this.execute("post", `/CertifyInstall/trackunit?userName=${userName}`,payload);
  },
  // readStats
  // getStatsData(companyId){
  //   return this.execute("get", `/Stats/company/${companyId}`);
  // }

  // home page installations apis 
  // Installations By Month 
  getInstallByMonth(){
    return this.execute("get", `/Stats/InstallationsByMonth`);
  },
  // Installations By Company 
  getInstallByCompany(fromDate,toDate){
    return this.execute("get", `/Stats/InstallationsByCompany?fromDate=${fromDate}&toDate=${toDate}`);
  },
  getCompanyServiceTypeData() {
    return this.execute("get", `/Company/Defaultservicetype`);
  },
  createCompanyServiceTypeData(payload) {
    return this.execute("POST", `/Company/Defaultservicetype` ,payload);
  },
  getCompanyServiceTypeDataById(customerId){
    return this.execute("get", `/Company/Defaultservicetype/${customerId}`);
  },
  updateCompanyServiceTypeData(payload){    
    return this.execute("POST", `/Company/Defaultservicetype` ,payload);
  },
  deleteCompanyServiceType(customerId){
    return this.execute("delete", `/Company/Defaultservicetype/${customerId}`);
  },
  getTUSerialUpdateData(assetId) {
    return this.execute("get", `/AssetTool/Assettuserial/${assetId}`);
  },
  updateTUSerial(payload) {
    return this.execute("PUT", `/AssetTool/Assettuserial` ,payload);
  },
  //Fit Guides
  getGuidesData() {
    return this.execute("get", `/Discovery/Guides`);
  },
  createGuideData(payload) {
    return this.execute("post", `/Discovery/Guides`, payload);
  },
  getGuideDataById(id){
    return this.execute("get", `/Discovery/Guidesrecord/${id}`);
  },
  updateGuideData(guideid , payload){    
    return this.execute("put", `/Discovery/Guides/${guideid}` ,payload);
  },
  deleteGuide(guideid){
    return this.execute("delete", `/Discovery/Guides/${guideid}`);
  },
  // FIT APP sync pending report
  getSyncPendingReport(searchText,contractor) {
    return this.execute("get", `/Discovery/SyncPending?searchText=${searchText}&contractor=${contractor}`);
  },
  getAverageInstallationTime(fromDate, toDate, contractorId, customerIds) {
    return this.execute(
      "get",
      `InstallationGraph/AverageInstallationTime?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerIds}&contractor=${contractorId}`
    );
  },
  getInstallationCountByContractor(fromDate, toDate, contractorId, customerIds) {
    return this.execute(
      "get",
      `InstallationGraph/InstallationsByDay?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerIds}&contractor=${contractorId}`
    );
  },
  getInstallationCountByCustomer(fromDate, toDate, contractorId, customerIds) {
    return this.execute(
      "get",
      `InstallationGraph/InstallationsByCustomer?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerIds}&contractor=${contractorId}`
    );
  },
  getInstallationCountByWeek(fromDate, toDate, contractorId, customerIds) {
    return this.execute(
      "get",
      `InstallationGraph/InstallationsByWeek?fromDate=${fromDate}&toDate=${toDate}&customerId=${customerIds}&contractor=${contractorId}`
    );
  },
  getContractorCustomers(contractorId) {
    return this.execute("get", `/Contractor/${contractorId}/customers`);
  },
  //Add New Service Related API's
  getServicePictures(serviceId){
    return this.execute("get", `/ServiceTool/ServicePhotos?serviceId=${serviceId}`);
  },
  addUpdateServicePictures(payload) {
    return this.execute("post", `/ServiceTool/ServicePhotosUpdate`, payload);
  },
  checkMachineExisted(machineValue,companyId){
    return this.execute("get", `/AssetTool/AssetFK?searchText=${machineValue}&companyId=${companyId}`);
  },
  createNewServiceRecord(payload){
    return this.execute("post", `/ServiceTool/Services`, payload);
  },
  createNewEquipmentRecord(payload){
    return this.execute("post", `/AssetTool/Assets`, payload);
  },
  getAllBranches(companyId){
    return this.execute("get", `/Branch/${companyId}`);
  },
  getCompanyPriceDataById(customerFk){
    return this.execute("get", `/Customer/CustomerPrice?customerFk=${customerFk}`);
  },
  addUpdateCompanyPriceData(customerFk,userId,payload){
    return this.execute("post", `/Customer/CustomerPrice?customerFk=${customerFk}&userId=${userId}`,payload);
  },
  // Branches in Field Install Tool 
  getBranchDataById(branchId) {
    return this.execute("get", `/branch/detail/${branchId}`);
  },
  getBranchesData() {
    return this.execute("get", `/branch/all`);
  },
  createBranchData(payload){
    return this.execute("post", `/branch`, payload);
  },
  updateBranchData(branchId,payload){
    return this.execute("put", `/branch/${branchId}`, payload);
  },
  deleteBranch(branchId, userName) {
    return this.execute("delete", `/branch/${branchId}?modifiedBy=${userName}`);
  },
  getBranchCheckInData(customer,fromDate,toDate) {
    return this.execute("get", `/Discovery/CheckIn/report?customerId=${customer}&fromDate=${fromDate}&toDate=${toDate}`);
  },
  resetServiceRecord(serviceId, payload) {
    return this.execute("put", `/ServiceTool/Reset/${serviceId}`, payload);
  },
  UnitInstallationByDayWeek(companyid, fromDate = null, toDate = null) {
    return this.execute(
      "get",
      `/Stats/UnitInstallationByDayWeek?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`
    );
  },
  getInstallsBydayExcel(companyId) {
    return this.execute(
      "get",
      `/Stats/InstallationsByDay/Download?companyId=${companyId}`
    );
  },
  getInstallsByWeekExcel(companyId) {
    return this.execute(
      "get",
      `/Stats/InstallationsByWeek/Download?companyId=${companyId}`
    );
  },
};
