import { mapGetters } from "vuex";
import { hasAccess } from "../../../config/admin.route.config";
import rules from "../../../utils/rules";
// import api from "@/api/api.service";
import companyapi from "@/api/company.service";
import EventBus from "../../../utils/EventBus";
import appsheettoolsService from "../../../api/appsheettools.service";

export default {
  name: "customer-dialog",
  components: {},
  props: ["isCustombtn"],
  data() {
    return {
      // isCustombtn: false,
      userdialog: false,
      appsheetId: "",
      isOEMCompany: false,
      OEMCompanyName: "",
      parentcompaniesList: [],
      companySelected: "",
      loading: false,
      valid: true,
      details: "",
      rules: rules,
      companyName: "",
      trackunitToken: "",
      TrackunitTokenError: "",
      name: "",
      email: "",
      phone: "",
      trackunitnumber: "",
      clientId: "",
      clientSecret: "",
      userName: "",
      password: "",
      userRole: "",
      isAccess: {
        reportAbug: true,
        reportRequest: true,
      },
      openedPanel: null,
      serviceTypes : [],
      defaultServiceType:""
    };
  },
  computed: {
    ...mapGetters(["toolbarDense", "navbarShow", "roles"]),
    toggleNavbarIcon() {
      return this.navbarShow
        ? "mdi-format-indent-decrease"
        : "mdi-format-indent-increase";
    },
  },
  created() {
    EventBus.$on("customer-got-triggered", () => {
      (this.rules = rules), (this.userdialog = true);
    });
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    this.isCustomer = this.roles == "Customer" ? true : false;
    this.isAccess = hasAccess.dashboard[this.roles]
      ? hasAccess.dashboard[this.roles]
      : this.isAccess;

    this.GetServiceTypes();
  },
  methods: {
    getOEMCompanyName() {
      if(this.isOEMCompany == true && this.OEMCompanyName == ""){
      this.OEMCompanyName = this.companyName;
      }
    },
    toggleNavbar() {
      this.$store.dispatch("NavbarToggle");
    },
   
    async SaveOnBoardingReport() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let oemCompanyName = "";
        if(this.isOEMCompany == true){
          oemCompanyName = this.OEMCompanyName
        }else{
          oemCompanyName = "";
        }
        let payload1 = {
          name: this.companyName,
          accountNo: this.trackunitnumber,
          contactName: this.name,
          isOEMCompany: this.isOEMCompany,
          oemCompanyName : oemCompanyName,
          contactPhone: this.phone,
          contactEmail: this.email,
          parentId: this.companySelected,
          tuClassicAccount: {
            token: this.trackunitToken,
          },
          appSheetAccount: null,
          irisAccount: {
            clientId: this.clientId,
            clientSecret: this.clientSecret,
            userName: this.userName,
            password: this.password,
          },
          userId: this.userId,
        };
       
        try {

          const {account = null,message} = await this.createFitManagementAccount();

          if(account == null){
            this.$toast.error(`Unable to create Fit account -> ${message}`);
            this.loading = false;
            return;
          }

          payload1.appSheetAccount = {
            id: account.id,
            name: account.customerName,
          };

          let apiResponse = await companyapi.createCustomerOnboarding(payload1);
          if (apiResponse.isSuccess == true) {
            this.loading = false;
            this.userdialog = false;
            this.$refs.form.reset();
            this.$toast.success(apiResponse.systemMessage);

          } else {
            this.loading = false;

            this.$toast.error("Unable to create customer");
          }
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
        
      }
    },
    async CompanyList() {
      try {
        let response = await companyapi.getAllCustomers();
        if (response) {
          if (this.roles == "User") {
            this.parentcompaniesList = response.data.filter(
              (c) => this.$store.getters.customerIds.includes(c.id)
            );
          } else {
            this.parentcompaniesList = response.data;
          }
        }
      } catch (error) {
        return;
      }
    },
    ClearDialog() {
      this.openedPanel = null;
      this.userdialog = false;
      this.$refs.form.reset();
      this.companyName = "";
      this.trackunitToken = "";
      this.name = "";
      this.email = "";
      this.phone = "";
      this.trackunitnumber = "";
      this.clientId = "";
      this.clientSecret = "";
      this.userName = "";
      this.password = "";
      this.isOEMCompany = false;
      this.OEMCompanyName = "";
      this.defaultServiceType = "";
    },
    async createFitManagementAccount() {
      let result = {account:null,message:null};
      try {
        let fitAccountPayload = {
          customerName: this.companyName,
          trackunitAccountNumber:  this.trackunitnumber,
          pointOfContact: this.name,
          additionalNotes: "",
          defaultServiceType: this.defaultServiceType,
          createdBy: this.$store.getters.name,
        };
       
        let response = await appsheettoolsService.createCompanyData(fitAccountPayload);
        if (response.isSuccess) {
          result.account = response.data;
        } else {
          result.message = response.systemMessage;
        }
      } catch (error) {
        result.message = `Error: ${error.message||''}`;
      }
      return result;
    },
    async GetServiceTypes() {
      try {
        let response = await appsheettoolsService.getAuditServiceTypes();
        if (response) {
          this.serviceTypes = response;
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
};
