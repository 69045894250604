module.exports = {
  Environment: "Production",
  API_ENDPOINT: 'https://gateway.install.trackunit.app/deployment/v1.0',
  COMPANY_ENDPOINT: 'https://gateway.install.trackunit.app/companyms/v1.0',
  HEALTH_CHECKS_ENDPOINT: 'https://gateway.install.trackunit.app/report/v1.0/',
  TOOLS_ENDPOINT: 'https://gateway.install.trackunit.app/group/v1.0',
  HOURS_TOOLS_ENDPONT: 'https://gateway.install.trackunit.app/hours/v1.0',
  METADATA_TOOLS_ENDPOINT: 'https://gateway.install.trackunit.app/metadata/v1.0',
  APPSHEET_TOOLS_ENDPOINT: 'https://gateway.install.trackunit.app/appsheet/v1.0/',
  RELEASES_ENDPOINT: "https://gateway.install.trackunit.app/deployment/v1.0/",
  SUPPORT_TICKETS_ENDPOINT:"https://gateway.install.trackunit.app/deployment/v1.0/",
  CLIENT_ID: "11513BD4-11E6-4270-A91A-FC4FB69BE9AE",
  PROJECT_ID: "a4beb417-b9ed-4bfb-b91d-c1ccd922b746",
  AUTH_TOKEN_INTERVAL: 3480000,
  FINANCEREPORT_ID:"F8DD6947-AACB-492B-A3B0-261197F8B307",
  FIT_ENDPOINT: "https://gateway.install.trackunit.app/appsheet/v1.0/",
  USER_ENDPOINT: "https://gateway.install.trackunit.app/user/v1.0/",
  VUE_APP_OKTA_OAUTH2_CLIENT_ID_SPA:'0oao5qmsvttlesqNo357',
  VUE_APP_OKTA_CLIENT_ORGURL:'https://trackunit-portal.okta.com/oauth2/aus2u3jix1dWU7fwt356'
};
