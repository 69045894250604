// import axios from "axios";
import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.RELEASES_ENDPOINT,
//   json: true,
// });

export default {
  async execute(method, resource, data,requestheader = {}) {
    return axios.releasesEndPoint({
      method,
      url: resource,
      data,
      headers:requestheader
    }).then((req) => {
      return req.data;
    });
  },
  getReleases(headerpayload,releaseStatus) {
    let envvalue="";
    env.Environment == 'Production'? envvalue ="production" : envvalue = "staging";
    return this.execute("get", `/Releases?status=${releaseStatus}&environment=${envvalue}`,null,headerpayload);
  },
  getReleasesById(releaseId,headerpayload){
    return this.execute("get", `/Releases/${releaseId}`,null,headerpayload);
  },
  UpdateRelease(releaseId,payload,headerpayload){
    return this.execute("put", `/Releases/${releaseId}`,payload,headerpayload);
  },
  CreateNewRelease(payload,headerpayload){
    return this.execute("post", `/Releases`,payload,headerpayload);
  },
  GetReleasesNotes(releaseNotesId,headerpayload){
    return this.execute("get", `/Releases/ReleaseNotes/${releaseNotesId}`,null,headerpayload);
  },
  UpdateReleaseNotes(releaseNotesId,payload,headerpayload){
      return this.execute("put", `/Releases/ReleaseNotes/${releaseNotesId}`,payload,headerpayload);
  },
  DeleteReleaseById(releaseId,headerpayload){
    return this.execute("delete", `/Releases/${releaseId}`,null,headerpayload);
  },
  DeleteReleaseNotes(releaseNotesId,headerpayload){
    return this.execute("delete", `/Releases/ReleaseNotes/${releaseNotesId}`,null,headerpayload);
  },
  AddNewDetails(payload,headerpayload){
    return this.execute("post", `/Releases/ReleaseNotes`,payload,headerpayload);
  },
  getProdReleases(headerpayload){
    return this.execute("get", `/Releases/StagingEnviromentReleases`,null,headerpayload);
  }
};
