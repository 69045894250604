import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAxios from "vue-axios";
import axios from "axios";
import "vuetify/dist/vuetify.min.css";
import "./assets/styles/styles.scss";
import "./router/permission";
// import "./api/httpClient";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import VueApexCharts from "vue-apexcharts";
import VueMask from 'v-mask';
import "@/utils/filters";
import "font-awesome/css/font-awesome.min.css";
import VueSelectImage from "vue-select-image";
import "vue-select-image/dist/vue-select-image.css";
import releaselist from "../src/components/Releases/ReleasesList";
import viewrelease from "../src/components/Releases/viewReleases";
console.log(releaselist, viewrelease);
import supportticket from "../src/components/Support/SupportTickets";
//import viewsupportticket from "../src/components/Support/ViewSupportTicket";
console.log(supportticket)
Vue.use(VueSelectImage);
Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.use(VueMask);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueAxios, axios);
Vue.use(VueToast, {
  position: "top",
  duration: 3000,
  dismissible: true,
  queue: true
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
