// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
import config from "../../secrets/config";
import store from "../store";
// const client = axios.create({
//   baseURL: env.RELEASES_ENDPOINT,
//   json: true
// });
export default {
    // async execute(method, resource, data) {
    //   return client({
    //     method,
    //     url: resource,
    //     data,
    //     headers: {
    //       clientId: config.CLIENT_ID,
    //       projectid:config.PROJECT_ID,
    //       roleId: store.getters.roles,
    //     },
    //   }).then(req => {
    //     return req.data;
    //   });
    // },
     async execute(method, resource, data) {
      return axios.supportTicketEndPoint({
        method,
        url: resource,
        data,
        headers: {
          clientId: config.CLIENT_ID,
          projectId: config.PROJECT_ID,
          roleId: store.getters.roles,
        },
      }).then(req => {
        return req.data;
      });
    },
    reportATicket(payload) {
      return this.execute("post", "/Bugs", payload);
    },
    getTickets(userId, ticketStatus, tagid) {
      return this.execute(
        "get",
        `/Bugs/userId?userId=${userId}&status=${ticketStatus}&tags=${tagid}`
      );
    },
    getTagData() {
      return this.execute("get", `/Tags`);
    },
    getBugView(bugId) {
      return this.execute("get", `/Bugs/${bugId}`);
    },
    updateABug(payload, bugId) {
      return this.execute("put", `/Bugs/${bugId}`, payload);
    },
    getAttachments(module, Id) {
      return this.execute("get", `/${module}/${Id}/attachments`);
    },
    downloadAttachment(module, Id, attachmentId) {
      return this.execute("get", `/${module}/${Id}/attachments/${attachmentId}`);
    },
    uploadAttachments(module, Id, payload) {
      return this.execute("post", `/${module}/${Id}/attachment`, payload);
    },
    updateBugStatus(bugId, payload) {
      return this.execute("put", `/Bugs/${bugId}/Status`, payload);
    },
    updateBillable(bugId, payload) {
      return this.execute("put", `Bugs/${bugId}/Billable`, payload);
    },
    UpdateApprove(module, bugId, payload) {
      return this.execute("put", `/${module}/${bugId}/ApproveToClose`, payload);
    },
    getComments(module, commentId) {
      return this.execute("get", `/${module}/${commentId}/comment`);
    },
    postComment(module, payload) {
      return this.execute("post", `/${module}/comment`, payload);
    },
    updateComments(bugId, commentId, payload, module) {
      return this.execute(
        "put",
        `/${module}/${bugId}/comment/${commentId}`,
        payload
      );
    },
    deleteComments(bugId, commentId, module) {
      return this.execute(
        "delete",
        `/${module}/${bugId}/comments?commentId=${commentId}`
      );
    },
    postEmailCustomer(bugId,userId){
      return this.execute("post", `Bugs/email?bugId=${bugId}&userId=${userId}`);
    },
  };
  