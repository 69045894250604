// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.API_ENDPOINT,
//   // baseURL: `http://35.236.93.48/api/Client`,
//   json: true
// });

export default {
  async execute(method, resource, data) {
    return axios.apiEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  createUser(payload) {
    return this.execute("post", "/Registration", payload);
  },
  updateUser(payload, userId) {
    return this.execute("put", `/user/${userId}`, payload);
  },
  createReportRequest(payload) {
    return this.execute("post", "/Reports", payload);
  },
  getReports() {
    return this.execute("get", "/InternalReport");
  },
  getReportsByCustomerId(referenceId) {
    return this.execute("get", `/InternalReport/customer/${referenceId}/prod`);
  },
  getUsers() {
    return this.execute("get", "/user");
  },
  getUser(userId) {
    return this.execute("get", `/User/${userId}`);
  },
  removeUser(userId) {
    return this.execute("delete", `/user/${userId}`);
  },
  getReportRequests() {
    return this.execute("get", "/Reports");
  },
  getReportsByEnv(env) {
    return this.execute("get", `/InternalReport/customers/${env}`);
  },
  getBugs(userId, ticketStatus) {
    return this.execute("get", `/Bugs/${userId}/${ticketStatus}`);
  },
  getBugView(bugId) {
    return this.execute("get", `/Bugs/${bugId}`);
  },
  reportABug(payload) {
    return this.execute("post", "/Bugs", payload);
  },
  updateABug(payload, bugId) {
    return this.execute("put", `/Bugs/${bugId}`, payload);
  },
  updateRequest(payload, bugId) {
    return this.execute("put", `/Reports/${bugId}`, payload);
  },
  getReportView(requestId) {
    return this.execute("get", `/Reports/${requestId}`);
  },
  getComments(module, commentId) {
    return this.execute("get", `/${module}/${commentId}/comment`);
  },
  postComment(module, payload) {
    return this.execute("post", `/${module}/comment`, payload);
  },
  UpdateApprove(module, bugId, payload) {
    return this.execute("put", `/${module}/${bugId}/ApproveToClose`, payload);
  },
  updateComments(bugId, commentId, payload, module) {
    return this.execute(
      "put",
      `/${module}/${bugId}/comment/${commentId}`,
      payload
    );
  },
  deleteComments(bugId, commentId, module) {
    return this.execute(
      "delete",
      `/${module}/${bugId}/comments?commentId=${commentId}`
    );
  },
  getReportAttachments(requestId) {
    return this.execute("get", `/Request/Attachments/${requestId}`);
  },
  getAttachments(module, Id) {
    return this.execute("get", `/${module}/${Id}/attachments`);
  },
  downloadAttachment(module, Id, attachmentId) {
    return this.execute("get", `/${module}/${Id}/attachments/${attachmentId}`);
  },
  uploadAttachments(module, Id, payload) {
    return this.execute("post", `/${module}/${Id}/attachment`, payload);
  },
  getStats(module) {
    return this.execute("get", `/${module}/stats/status`);
  },
  updateStatus(reportId, payload) {
    return this.execute("put", `/Reports/${reportId}/Status`, payload);
  },
  updateReportStatus(reportId, payload, status) {
    return this.execute(
      "put",
      `/Reports/${status}/status?reportId=${reportId}`,
      payload
    );
  },
  updateCustomerStatus(Id, payload, status) {
    return this.execute("put", `/Customer/${Id}/${status}/Status`, payload);
  },
  updateBugStatus(bugId, payload) {
    return this.execute("put", `/Bugs/${bugId}/Status`, payload);
  },
  getCompanyList() {
    return this.execute("get", `/Configurations/company`);
  },
  getReportTypes(reportType) {
    return this.execute("get", `/Configurations/reporttypes/${reportType}`);
  },
  updatepassword(payload, emailId) {
    return this.execute(
      "put",
      `/Registration/${emailId}/ChangePassword`,
      payload
    );
  },
  getNotifications(userId) {
    return this.execute("get", `/Notifications/User/${userId}`);
  },
  updateNotification(notifyId) {
    return this.execute("put", `/Notifications/${notifyId}`);
  },
  MarkAllAsViewed(userId) {
    return this.execute("put", `/Notifications/${userId}/MarkAllAsViewed`);
  },
  getPatchUpdates(userId) {
    return this.execute("get", `/PatchUpdates/User/${userId}`);
  },
  getPatchUpdateView(patchId) {
    return this.execute("get", `/PatchUpdates/${patchId}`);
  },
  updatePatch(payload, patchId) {
    return this.execute("put", `/PatchUpdates/${patchId}`, payload);
  },
  newUpdate(payload) {
    return this.execute("post", `/PatchUpdates`, payload);
  },
  removeUpdate(payload) {
    return this.execute("delete", `/PatchUpdates`, payload);
  },
  createCustomerOnboarding(payload) {
    return this.execute("post", `/Customer`, payload);
  },
  getCustomers() {
    return this.execute("get", `/Customer`);
  },
  getCustomerById(id) {
    return this.execute("get", `/Customer/${id}`);
  },
  updateCustomer(payload, id) {
    return this.execute("put", `/Customer/${id}`, payload);
  },
  getProfile(userId) {
    return this.execute("get", `/Profile/${userId}`);
  },
  updateProfileImage(userId, payload) {
    return this.execute("put", `/Profile/${userId}/ProfileImage`, payload);
  },
  updateProfile(userId, payload) {
    return this.execute("put", `/Profile/${userId}`, payload);
  },
  createDataSource(payload) {
    return this.execute("post", `/DataSources`, payload);
  },
  getDataSources() {
    return this.execute("get", `/DataSources`);
  },
  updateBugPriority(ticketId, payload) {
    return this.execute("put", `/Bugs/${ticketId}/Priority`, payload);
  },
  Resetpassword(emailId) {
    return this.execute("put", `/Registration/${emailId}/ResetPassword`);
  },
  NewReport() {
    return this.execute("get", `/Request/New`);
  },
  GetAssumptions() {
    return this.execute("get", `/Assumptions/Assumptions`);
  },
  createNewReportRequest(payload) {
    return this.execute("put", `/Request`, payload);
  },
  getNewReportRequestList() {
    return this.execute("get", `Request`);
  },
  ViewReportRequest(requestId) {
    return this.execute("get", `/Request/${requestId}`);
  },
  uploadReportAttachments(payload, requestId) {
    return this.execute("post", `/Request/Attachments/${requestId}`, payload);
  },
  getExistingReport(reportId) {
    return this.execute("get", `/Request/report/${reportId}`);
  },
  genearteJiraTicket(payload) {
    return this.execute("POST", `Request/JiraTicket`, payload);
  },
  downloadReportAttachment(attachmentId) {
    return this.execute("get", `/Request/Attachment/${attachmentId}`);
  },
  getCustomerCredentials(customerId) {
    return this.execute("get", `/Customer/CustomerCredentials/${customerId}`);
  },
  getRequestComments(requestId) {
    return this.execute("get", `Request/${requestId}/Comments`);
  },
  postRequestComment(requestId, payload) {
    return this.execute("post", `Request/${requestId}/Comments`, payload);
  },
  updateRequestComments(requestId, commentId, payload) {
    return this.execute(
      "put",
      `Request/${requestId}/Comments/${commentId}`,
      payload
    );
  },
  deleteRequestComments(requestId, commentId) {
    return this.execute(
      "delete",
      `/Request/${requestId}/Comment?commentId=${commentId}`
    );
  },
  setdefaultCustomer(payload) {
    return this.execute("put", `/Customer/defaultCustomer`, payload);
  },
  getdefaultCustomer(userId) {
    return this.execute("get", `/Customer/DefaultCustomer/${userId}`);
  },
  createClientOnboarding(payload) {
    return this.execute("post", `/Client/register`, payload);
  },
  getClients() {
    return this.execute("get", `/Client`);
  },
  getClient(clientId) {
    return this.execute("get", `/Client/${clientId}`);
  },
  deleteClient(clientId) {
    return this.execute("delete", `/Client?id=${clientId}`);
  },
  updateClient(clientId, payload) {
    return this.execute("put", `/Client?id=${clientId}`, payload);
  },
  gerReporttypes() {
    return this.execute("get", `/Client/reporttypes`);
  },
  getReportsCustomer(customerId) {
    return this.execute("get", `/Customer/${customerId}`);
  },
  updateReportsCustomer(customerId, payload) {
    return this.execute("put", `/Customer/${customerId}`, payload);
  },
  getSelectedReports(customerId) {
    return this.execute("get", `/Customer/${customerId}/Reports`);
  },
  updateSelectedReports(customerId, payload) {
    return this.execute("put", `/Customer/${customerId}/Reports`, payload);
  },
  getBigQueryChartsData() {
    return this.execute("get", `/Stats/bigquery`);
  },
  getSpecificBigQueryChartData(title,companyId) {
    return this.execute("get", `/Stats/bigquery/${title}?companyId=${companyId}`);
  },
  //Get Tools
  getTools(userId) {
    return this.execute("get", `/User/${userId}/Tools`);
  },
  // Report Tracking 
  getReportDetails(id){
    return this.execute("get", `/ReportTracking/${id}`);
  },
  saveReportDetails(payload){
    return this.execute("post", `/ReportTracking`, payload);
  },
  getReportTrackingData() {
    return this.execute("get", `/ReportTracking`);
  },
  saveReportComment(payload){
    return this.execute("post", `/ReportTracking/Comment`, payload);
  },
  updateReportComments(payload){
    return this.execute("put", `/ReportTracking/commentUpdate`, payload);
  },
  deleteReportComment(commentId){
    return this.execute("delete", `/ReportTracking/${commentId}/comment`);
  },
  updateReportDetails(id,payload){
    return this.execute("put", `/ReportTracking/${id}`, payload);
  },
  //Uplaod Report Tracking atatchments
  uploadReportTrackingAttachments(id,payload){
    return this.execute("post", `/ReportTracking/Attachment?reportTrackingId=${id}`,payload);
  },
  getsampleReportAttachments(id){
    return this.execute("get", `/ReportTracking/${id}/Attachment`);
  },
  deleteSampleReportTracking(fileId){
    return this.execute("delete", `/ReportTracking/${fileId}/Attachment`);
  },
  downloadReportTrackingAttachment(fileId){
    return this.execute("get", `/ReportTracking/${fileId}/AttachmentDetails`);
  }
};
