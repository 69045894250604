const packageJson = require('../../package.json');

const env = process.env.NODE_ENV || 'development';
console.log("environment", env);
const config = require("../../secrets/config");
module.exports = {
  title: packageJson.title,
  version: packageJson.version,
  settings: {
    dense: false,
    footer: false,
    navbar: {
      show: true,
      dense: false,
      logo: true,
    }
  },
  Environment:config.Environment,
  API_ENDPOINT: config.API_ENDPOINT,
  SUPPORT_TICKETS_ENDPOINT: config.SUPPORT_TICKETS_ENDPOINT,
  COMPANY_ENDPOINT: config.COMPANY_ENDPOINT,
  HEALTH_CHECKS_ENDPOINT: config.HEALTH_CHECKS_ENDPOINT,
  TOOLS_ENDPOINT: config.TOOLS_ENDPOINT,
  HOURS_TOOLS_ENDPONT: config.HOURS_TOOLS_ENDPONT,
  METADATA_TOOLS_ENDPOINT: config.METADATA_TOOLS_ENDPOINT,
  APPSHEET_TOOLS_ENDPOINT: config.APPSHEET_TOOLS_ENDPOINT,
  RELEASES_ENDPOINT: config.RELEASES_ENDPOINT,
  FIT_ENDPOINT: config.FIT_ENDPOINT,
  USER_ENDPOINT: config.USER_ENDPOINT,
  AUTH_TOKEN_INTERVAL: config.AUTH_TOKEN_INTERVAL,
  FINANCEREPORT_ID:config.FINANCEREPORT_ID,
  VUE_APP_OKTA_OAUTH2_CLIENT_ID_SPA: config.VUE_APP_OKTA_OAUTH2_CLIENT_ID_SPA,
  VUE_APP_OKTA_CLIENT_ORGURL: config.VUE_APP_OKTA_CLIENT_ORGURL
}