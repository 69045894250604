<template>
  <v-menu
    class="notify-menu"
    offset-y
    max-height="500"
    max-width="600"
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
    :close-on-content-click="false"
    offset-x
    :content-class="notificationsCount < 5 || items.length < 5 ? 'my-menu' : ''"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon text v-on="on" class="ml-3">
        <v-badge
          class="notif-badge m-auto"
          color="$neutral-pink-1"
          overlap
          bordered
        >
          <template v-slot:badge>
            <span>{{ notificationsCount }}</span>
          </template>
          <v-icon :class="isBellAnimation ? 'bell' : ''"
            >mdi-bell-outline</v-icon
          >
        </v-badge>
      </v-btn>
    </template>
    <v-card :width="items.length > 0 ? '400' : ''">
      <v-list class="pa-0" two-line subheader>
        <v-row v-if="notificationsCount > 0 || items.length > 0">
          <v-col cols="6" md="6">
            <v-subheader class="notify-title">Notifications</v-subheader>
          </v-col>
          <v-col cols="6" md="6" class="text-right">
            <v-btn
              class="mark-all-btn"
              v-if="notificationsCount != 0"
              x-small
              rounded
              outlined
              color="#0065e5"
              dark
              @click="SaveMarkAsAllViewed()"
              >Mark all as read</v-btn
            >
          </v-col>
        </v-row>
        <v-divider />
        <v-list-item-group v-if="items.length > 0">
          <template v-for="(item, index) in items">
            <v-list-item
              :key="index + item.id"
              @click="handleClickItem(item)"
              class="notify-list"
            >
              <v-list-item-avatar
                :color="item.isViewed ? 'grey lighten-1' : '#872d00'"
                width="30"
                height="30"
                min-width="30"
              >
                <span class="white--text">{{ item.name }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="item.isViewed ? '' : 'notify-font-style'"
                  ><span>{{ item.details }}</span> -
                  <span>{{ item.key }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </template>
        </v-list-item-group>
        <v-list-item-group v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> You have no notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider />
      <v-subheader v-if="isShow && notificationsCount >= 5">
        <v-row class="show-mark-btn">
          <v-col cols="12" md="12" class="text-center">
            <v-btn x-small class="secondary-btn" @click="showAllNotifications"
              >View All</v-btn
            >
          </v-col>
        </v-row>
      </v-subheader>
    </v-card>
  </v-menu>
</template>

<style scoped>
.notif-badge {
  /* margin-right:50px !important; */
}

.notify-icon {
  font-size: 20px !important;
}
.notify-title {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.notify-font-style {
  font-weight: 600 !important;
}
.notify-menu {
  height: 50% !important;
}
.notify-list {
  padding: 0px 30px !important;
}
.notify-list .v-list-item__title {
  font-size: 0.8rem !important;
}
.show-mark-btn {
  padding: 0px 16px;
}
.mark-all-btn {
  margin: 16px 16px 0px 16px;
}
.my-menu {
  margin-top: 5px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 50px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.bell {
  display: block;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.v-menu__content {
  top: 60px !important;
  box-shadow: rgb(26 27 28 / 15%) 0px 1px 1px,
    rgb(26 27 28 / 13%) 0px 0px 1px 1px !important;
  border: none !important;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
<script>
import apiService from "@/api/api.service";
export default {
  name: "AppNotification",
  data: () => ({
    isShow: true,
    items: [],
    userId: null,
    notificationsCount: 0,
    notificationsList: [],
    isBellAnimation: false
  }),
  created() {
    this.userId = this.$store.getters.userId;
    this.readNotifications();
  },
  methods: {
    async readNotifications() {
      try {
        let result = await apiService.getNotifications(this.userId);
        if (result) {
          result.map(
            item => (item.name = item.details.charAt(0).toUpperCase())
          );
          this.notificationsList = result;
          this.items = result.slice(0, 5);
          this.notificationsCount = result.filter(
            item => item.isViewed == false
          ).length;
          if (this.notificationsCount > 0) {
            this.isBellAnimation = true;
            setTimeout(() => {
              this.isBellAnimation = false;
            }, 10000);
          }
        }
      } catch (err) {
        console.log("Unable to load the Notifications" + err);
      }
    },
    showAllNotifications() {
      this.isShow = false;
      this.items = this.notificationsList;
    },
    async handleClickItem(item) {
      if (!item.isViewed) {
        item.isViewed = true;
        try {
          let result = await apiService.updateNotification(item.id);
          if (result.isSuccess == true) {
            this.notificationsCount -= 1;
          }
        } catch (err) {
          console.log(err);
          //this.$toast.error("Unable to update the Notifications" + err);
        }
      }
      if (item.entity == "Ticket" && item.urlkey) {
        this.$router
          .push({
            name: "viewTicket",
            params: { id: item.urlkey }
          })
          .catch(() => {});
      } else if (item.entity == "Report" && item.urlkey) {
        this.$router
          .push({
            name: "ViewReportRequest",
            params: { id: item.urlkey }
          })
          .catch(() => {});
      }
    },
    async SaveMarkAsAllViewed() {
      this.items = this.notificationsList;
      for (let item of this.items) {
        if (!item.isViewed) {
          item.isViewed = true;
        }
      }
      try {
        let apiResult = await apiService.MarkAllAsViewed(this.userId);
        if (apiResult.isSuccess == true) {
          this.notificationsCount = 0;
          this.$toast.success(" All Notifications are marked as viewed");
        }
      } catch (err) {
        this.$toast.error("Unable to save Mark All As Viewed" + err);
      }
      // }
    }
  }
};
</script>
